import { store } from 'src/redux/store';
import { RefreshUserToken, ReAuthoriseUser } from 'src/services/user-http.service';
import { SetUserServiceInfo } from 'src/redux/user/user.actions';
import { getUniversalCookies } from 'src/cookies/cookie';
import { environment } from 'src/environments/environment';

export const GetToken = async () => {
    const state = store.getState();

    let token = "Bearer " + state.user.userServiceDetails.token;
    let tokenExpiry = state.user.userServiceDetails.tokenExpiry;

    if (tokenExpiry > Date.now()) {
        return token
    } else {
        let email = state.user.userServiceDetails.email;
        let refreshToken = getUniversalCookies().get('RefreshToken');
        let data = await RefreshUserToken(email, refreshToken, environment.applicationId);

        if (!data.refreshToken) {
            data = await ReAuthoriseUser(email, environment.applicationId, true);
        }

        data.tokenExpiry = Date.now() + data.tokenExpiry - 5000;
        store.dispatch(SetUserServiceInfo(data));
        getUniversalCookies().set('RefreshToken', data.refreshToken);

        return "Bearer " + data.token
    }
}

export const GetTokenWithoutBearer = async () => {
    const state = store.getState();

    let token = state.user.userServiceDetails.token;
    let tokenExpiry = state.user.userServiceDetails.tokenExpiry;

    if (tokenExpiry > Date.now()) {
        return token
    } else {
        let email = state.user.userServiceDetails.email;
        let refreshToken = getUniversalCookies().get('RefreshToken');
        let data = await RefreshUserToken(email, refreshToken, environment.applicationId);

        if (!data.refreshToken) {
            data = await ReAuthoriseUser(email, environment.applicationId, true);
        }

        data.tokenExpiry = Date.now() + data.tokenExpiry - 5000;
        store.dispatch(SetUserServiceInfo(data));
        getUniversalCookies().set('RefreshToken', data.refreshToken);

        return data.token
    }
}