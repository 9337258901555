import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import SearchBar from 'src/components/buttons/search-icon-button.component';
import InfoWindowComponent from 'src/components/maps/infoWindowComponent';
import { Geocoding } from 'src/services/geocoder.service';
import { GetFleets } from 'src/services/fleet-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserParentOrganization } from 'src/redux/user/user.selectors';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import { QueryVehiclePositionByMarket, QueryVehiclePositionForMapView } from 'src/services/vehicle-pages-http.service';
import { styles } from 'src/utils/MapStyle';
import PuffLoader from "react-spinners/PuffLoader";
import SearchBarProvisioning from "src/components/buttons/search-button-provisioning.component";
import mapBlackVehicle from 'src/assets/img/vehicles/IconMap.svg';

const Map = ({ userOrg, userParentOrg, userMarketIds, mapheight, vehiclePositionSVT, setVeiclePositionSVT, setSelectedVIN, valueVIN, selectedVINMap, setAddressInputSVT, setSelectedVINMap, selectedVIN }) => {

    const [activeMarker, setActiveMarker] = useState(null);
    const [addressInput, setAddressInput] = useState('')
    const [searchVIN, setSearchVIN] = useState('')
    const [vehiclePosition, setVeiclePosition] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [markers, setMarkers] = useState([]);
    const [derivedLatLng, setDerivedLatLng] = useState({ lat: 57.71089814294498, lng: 11.945829642768519 });
    const [mapSpinner, setMapSpinner] = useState(false);
    const [mapIcon, setMapIcon] = useState(false);
    const [clearMarker, setClearMarker] = useState(false);
    const [message, setMessage] = useState('');
    const [displayVehicle, setDisplayVehicle] = useState(false);

    useEffect(() => {
        valueVIN == 5 && (searchVIN !== '' || selectedVINMap !== '') && handleTab(searchVIN || selectedVINMap || selectedVIN)
    }, []);

    const handleActiveMarker = (marker) => {
        let fleetIds = []
        let marketIds = userMarketIds;
        let vin = marker
        let vehiclePositionInstance = {
            "vin": "",
            "latitude": 0,
            "longitude": 0,
            "vehicleUpdatedAt": "",
            "color": "",
            "odometer": 0,
        }
        GetFleets(userParentOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })

                    QueryVehiclePositionForMapView(vin || selectedVIN, fleetIds, marketIds)
                        .then(data => {
                            if (data.ok == true) {
                                vehiclePositionInstance.vin = data.vehiclePosition.vin
                                vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                vehiclePositionInstance.color = data.vehiclePosition.color
                                vehiclePositionInstance.odometer = data.vehiclePosition.odometer
                                setVeiclePosition(vehiclePositionInstance);
                            }
                        })
                }
            })

        // }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        // const bounds = new window.google.maps.LatLngBounds();
        // markers.forEach(({ position }) => bounds.extend(position));
        // map.fitBounds(bounds);
        map.setOptions({
            disableDefaultUI: true,
            zoomControl: true,
            styles: styles,
        });
    };

    const handleSearch = () => {

        if (addressInput.trim()?.length != 0) {
            let address = addressInput;
            let marketId = userMarketIds;

            Geocoding({ address }).then(data => {
                if (data.status == 'ok') {
                    if (data.message && Array.isArray(data.message)) {

                        const retrieveCountry = data.message[0].address_components.find(address => address['types'].includes('country'))['short_name']
                        const retrieveLatLog = data.message[0].geometry['location']

                        setDerivedLatLng(retrieveLatLog)
                        setDerivedLatLng('retrieveLatLog')
                        const matchesMarket = marketId.find(element => {
                            if (element.includes(retrieveCountry)) {
                                return true;
                            }
                        });
                        if (matchesMarket !== undefined) {
                            let fleetIds = []
                            let vehiclePositionArray = [];
                            let marketIds = userMarketIds;

                            GetFleets(userParentOrg.id, userMarketIds)
                                .then(data => {
                                    if (data && Array.isArray(data)) {
                                        data.map(item => {
                                            fleetIds.push(item.id);
                                        })

                                        QueryVehiclePositionByMarket(fleetIds, marketIds, matchesMarket)
                                            .then(data => {
                                                if (data.ok == true) {
                                                    data.vehiclePositions.map(vehicle => {
                                                        vehiclePositionArray.push({
                                                            vin: vehicle.vin,
                                                            position: { lat: vehicle.latitude, lng: vehicle.longitude },

                                                        });
                                                    });
                                                }
                                                setMarkers(vehiclePositionArray)
                                            })
                                    }
                                })
                        } else {
                            setDialogMessage('Selected location is not part of your market. Please try again');
                            setOpenDialog(true);
                        }
                        setAddressInput('')
                    }
                } else {
                    setDialogMessage('Invalid location, please try again')
                    setOpenDialog(true);
                }
            })
        }
    }

    const handleSearchInput = (event) => {
        setAddressInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
    }

    const handleSearchInputSVT = (event) => {
        setSearchVIN(event.target.value)
        setSelectedVIN(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchVIN('');
            setMapSpinner(false);
            setMapIcon(false);
            setSelectedVIN('');
            setAddressInputSVT('');
            setSelectedVINMap('');
            setVeiclePositionSVT({});
            setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
            setMarkers([]);
        }
        if (event.target.value.length === 0 && searchVIN == '') {
            setClearMarker(true);
            setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
        }
    }

    const handleDialogOk = () => {
        setOpenDialog(false);
        setSearchVIN('');
        setMapIcon(false);
        setVeiclePositionSVT({});
        setDerivedLatLng({});
    }

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const textFieldKeypressSVT = (e, vin) => {
        if (e.key === 'Enter') {
            handleTab(vin);
        }
    }

    const handleTab = (vin) => {
        setMapIcon(true);
        setDisplayVehicle(true)
        let fleetIds = []
        vin = searchVIN.trim() || selectedVINMap
        let vehiclePositionInstance = {
            "vin": "",
            "latitude": 0,
            "longitude": 0,
            "vehicleUpdatedAt": "",
            "color": "",
            "odometer": 0,
            "street": "",
            "postalCode": "",
            "city": "",
            "country": ""
        };
        if (vin !== '') {
            setMapSpinner(true);
            GetFleets(userParentOrg.id)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data?.map(item => {
                            fleetIds.push(item.id);
                        })

                        QueryVehiclePositionForMapView(vin || selectedVIN, fleetIds, userMarketIds)
                            .then(data => {
                                if (data?.ok == true) {
                                    vehiclePositionInstance.vin = data.vehiclePosition.vin
                                    vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                    vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                    vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                    vehiclePositionInstance.color = data.vehiclePosition.color
                                    vehiclePositionInstance.odometer = data.vehiclePosition.odometer
                                    vehiclePositionInstance.street = data.vehiclePosition.addressData.street
                                    vehiclePositionInstance.postalCode = data.vehiclePosition.addressData.postalCode
                                    vehiclePositionInstance.city = data.vehiclePosition.addressData.city
                                    vehiclePositionInstance.country = data.vehiclePosition.addressData.country
                                    setVeiclePositionSVT(vehiclePositionInstance);
                                    setMarkers(vehiclePositionInstance);
                                    setDerivedLatLng({ lat: data.vehiclePosition.latitude, lng: data.vehiclePosition.longitude })
                                    setMapSpinner(false);
                                } else {
                                    setMapSpinner(false);
                                    setOpenDialog(true);
                                    setMessage('Vehicle not found');
                                    setDialogMessage('Please check VInListTable. If error persist, please reach out to system support');
                                    setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
                                    setVeiclePositionSVT({});
                                };
                            });
                    };
                });
            if (searchVIN === '') {
                setMapSpinner(false);
                setDerivedLatLng({});
            };
        };
        if (vin == '' || searchVIN == '') {
            setDerivedLatLng({});
        };
    };

    return (
        <>
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />
            {
                mapSpinner ?

                    <div style={{ position: 'absolute', width: '99.1%', height: '82vh', marginRight: '-35px', marginLeft: '18px', alignItems: 'center', backgroundColor: '#212121', marginTop: '150px', borderWidth: '11px', borderStyle: 'solid', borderImage: mapheight ? 'linear-gradient(135deg, #101010 20%, #373737 99%) 1' : 'linear-gradient(135deg, #000, #000) 1', borderBottomLeftRadius: '10px', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                            <PuffLoader color="#1EF1C6" size={200} />
                        </div>
                    </div>
                    :
                    <>
                        {
                            vehiclePositionSVT?.latitude === null || vehiclePositionSVT?.longitude === null ?
                                <div style={{ position: 'absolute', width: '99.1%', height: '720px', marginLeft: '-0.9vw', alignItems: 'center', backgroundColor: '#212121', marginTop: '-4px', display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                        Vehicle Position Not Available
                                    </div>
                                </div>
                                :
                                <GoogleMap
                                    onLoad={handleOnLoad}
                                    onClick={() => setActiveMarker(null)}
                                    mapContainerClassName='mapheightSVT'
                                    center={derivedLatLng}
                                    zoom={13}
                                >
                                    {
                                        mapheight ?
                                            <div style={{ width: '170%', marginTop: '15px' }}>
                                                <SearchBarProvisioning
                                                    connectivityColor='true'
                                                    placeholder={'VIN or Registration Number'}

                                                    handleSearch={handleTab}
                                                    handleSearchInput={handleSearchInputSVT}
                                                    onKeyPress={textFieldKeypressSVT}
                                                    value={searchVIN || selectedVINMap}
                                                />
                                            </div>
                                            :
                                            <div style={{ width: '58%', marginTop: '25px', marginLeft: '10px' }}>
                                                <SearchBar
                                                    handleSearch={handleSearch}
                                                    handleSearchInput={handleSearchInput}
                                                    onKeyPress={textFieldKeypress}
                                                    placeholder={'Search on map'}
                                                />
                                            </div>
                                    }
                                    {mapheight ?
                                        <>
                                            {
                                                searchVIN == '' && clearMarker ?
                                                    <></> : <>
                                                        <Marker
                                                            key={searchVIN}
                                                            position={mapIcon ? derivedLatLng : ''}
                                                            onClick={() => handleActiveMarker(searchVIN)}
                                                            icon={mapBlackVehicle}
                                                        >
                                                            {activeMarker === searchVIN ? (
                                                                <InfoWindow
                                                                    onCloseClick={() => setActiveMarker(null)}
                                                                >
                                                                    <div >
                                                                        <InfoWindowComponent vehiclePosition={vehiclePositionSVT} />
                                                                    </div>
                                                                </InfoWindow>
                                                            ) : null}
                                                        </Marker>
                                                    </>

                                            }
                                        </>


                                        :
                                        <>
                                            {markers.map(({ vin, position }) => (
                                                <Marker
                                                    key={vin}
                                                    position={position}
                                                    onClick={() => handleActiveMarker(vin)}
                                                >
                                                    {activeMarker === vin ? (
                                                        <InfoWindow
                                                            onCloseClick={() => setActiveMarker(null)}
                                                        >
                                                            <div >
                                                                <InfoWindowComponent vehiclePosition={vehiclePosition} />
                                                            </div>
                                                        </InfoWindow>
                                                    ) : null}
                                                </Marker>
                                            ))}
                                        </>
                                    }

                                </GoogleMap>
                        }
                    </>
            }
        </>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userParentOrg: selectUserParentOrganization(state)
});

export default connect(mapStateToProps, null)(Map);