import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserGraphData, selectUserParentOrganization } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';
import { QueryUserByEmail } from 'src/services/user-http.service'
import { GetMarketByOrganizationId } from 'src/services/market-http.service';

const MarketCard = ({ userOrg, userParentOrg, setDataLoaded, setQueryParamsMarketIds, graphData, setMarketconfig, multiMarketSelect, setToggleInfoWindow, setInputFieldMarker, setDisablebuttons, setActiveMarker, selectedMarkets, setSelectedMarkets,
    handleSearchToggle, handleGetLCCMCCData, showMCC, showLCC, showCCC, handleVehicleCountMCC, handleVehicleCountLCC, handleVehicleCountCCC, setShowChevronArrow, handleCloseMCCLCCTable, selectTable, isStandardMarket }) => {

    const [selected, setSelected] = React.useState([]);
    const [markets, setMarkets] = React.useState([]);
    const [checkedFlag, setCheckedFlag] = useState([]);
    const [sliderUser, setSliderUser] = useState({
        editMode: false
    });

    useEffect(() => {
        initializeMarkets();
        setSliderUser({
            editMode: true
        })
    }, [markets.length]);

    useEffect(() => {
        preselectMarkets();
    }, [sliderUser.editMode, markets.length]);

    const initializeMarkets = () => {
        let marketArray = [];
        GetMarketByOrganizationId(userParentOrg.id)
            .then(data => {
                Array.isArray(data) && data.length > 0 ? data.map(market => {
                    if(isStandardMarket == 'true') {
                        if(market.settings.isStandardMarket == true) {
                            marketArray.push({ key: market.gemsId, value: market.name });
                        }
                    } else {
                        marketArray.push({ key: market.gemsId, value: market.name })
                    }
                })
                    : console.log("Error Occured");
                setMarkets(marketArray);
            });
    };

    const preselectMarkets = () => {
        let marketSelectArray = [];
        setSelected([]);

        if (sliderUser.editMode === true) {

            QueryUserByEmail(graphData.mail).then(apiData => {
                Array.isArray(apiData.marketIds) && apiData.marketIds.length > 0 ? apiData.marketIds.map(market => {
                    marketSelectArray.push(market);
                })
                    : console.log("Error Occured");
                setSelected(marketSelectArray);
                setCheckedFlag(getDifference(marketSelectArray, markets.map(x => x.key)));
            }
            )
        }
        else {
            setSelected([]);
        }
    };

    const getDifference = (a, b) => {
        return a.filter(element => !b.includes(element)).concat(b.filter(element => !a.includes(element)));
    };

    const handleChange = (name, isDisabled = true) => (event) => {
        let updatedSelected = [];

        if (isDisabled) {
            updatedSelected = selected.filter((item) => item !== name)
            setQueryParamsMarketIds(updatedSelected);
            setMarketconfig(true);
            setDataLoaded(false);
        }
        if (!isDisabled) {
            updatedSelected = [...selected, name]
            setQueryParamsMarketIds(updatedSelected);
            setMarketconfig(true);
            setDataLoaded(false);
        }
        setSelected(updatedSelected);
    };

    const handleChangeMap = (name, isDisabled = true) => (event) => {
        let updatedSelected = [];
        let updatedSelectedmarket = [];

        if (isDisabled) {
            updatedSelected = selected.filter((item) => item !== name)
            updatedSelectedmarket = [...selectedMarkets, name]

            setDisablebuttons(true);
            setInputFieldMarker(true);


            if (isDisabled && updatedSelectedmarket.length > 1 && showMCC && showLCC && showLCC) {
                setToggleInfoWindow(false);
                let type = ['MCC', 'LCC', 'CCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleVehicleCountCCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (updatedSelectedmarket.length > 1 && showMCC && !showLCC) {
                setToggleInfoWindow(false);
                let type = ['MCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (updatedSelectedmarket.length > 1 && showMCC && !showLCC && !showCCC) {
                setToggleInfoWindow(false);
                let type = ['MCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (updatedSelectedmarket.length > 1 && !showMCC && showLCC) {
                setToggleInfoWindow(false);
                let type = ['LCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (updatedSelectedmarket.length > 1 && !showMCC && !showCCC && showLCC) {
                setToggleInfoWindow(false);
                let type = ['LCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (updatedSelectedmarket.length > 1 && !showMCC && showCCC && !showLCC) {
                setToggleInfoWindow(false);
                let type = ['CCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (isDisabled && updatedSelectedmarket.length >= 1 && !showMCC && !showLCC && !showCCC) {
                setToggleInfoWindow(true);
                handleSearchToggle(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };

        }
        if (!isDisabled) {
            updatedSelected = [...selected, name]
            updatedSelectedmarket = selectedMarkets.filter((item) => item !== name)
            setActiveMarker(null);
            if (!isDisabled && updatedSelectedmarket.length == 0) {
                setDisablebuttons(false);
                setShowChevronArrow(false);
                handleCloseMCCLCCTable();
            }

            if (!isDisabled && updatedSelectedmarket.length >= 1 && showMCC && showLCC) {
                setToggleInfoWindow(false);
                let type = ['MCC', 'LCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };

            if (!isDisabled && updatedSelectedmarket.length >= 1 && showMCC && showLCC && showCCC) {
                setToggleInfoWindow(false);
                let type = ['MCC', 'LCC', 'CCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };

            if (!isDisabled && updatedSelectedmarket.length >= 1 && showMCC && showCCC) {
                setToggleInfoWindow(false);
                let type = ['MCC', 'CCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };

            if (!isDisabled && updatedSelectedmarket.length >= 1 && showLCC && showCCC) {
                setToggleInfoWindow(false);
                let type = ['LCC', 'CCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };

            if (!isDisabled && updatedSelectedmarket.length >= 1 && !showMCC && showLCC) {
                setToggleInfoWindow(false);
                let type = ['LCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (!isDisabled && updatedSelectedmarket.length >= 1 && !showCCC && showLCC) {
                setToggleInfoWindow(false);
                let type = ['LCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };

            if (!isDisabled && updatedSelectedmarket.length >= 1 && !showMCC && !showCCC && showLCC) {
                setToggleInfoWindow(false);
                let type = ['LCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountLCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };

            if (!isDisabled && updatedSelectedmarket.length >= 1 && showMCC && !showLCC) {
                setToggleInfoWindow(false);
                let type = ['MCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };

            if (!isDisabled && updatedSelectedmarket.length >= 1 && showMCC && !showLCC) {
                setToggleInfoWindow(false);
                let type = ['MCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (!isDisabled && updatedSelectedmarket.length >= 1 && showMCC && !showLCC && !showCCC) {
                setToggleInfoWindow(false);
                let type = ['MCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (!isDisabled && updatedSelectedmarket.length >= 1 && showCCC && !showLCC) {
                setToggleInfoWindow(false);
                let type = ['CCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (!isDisabled && updatedSelectedmarket.length >= 1 && showCCC && !showMCC) {
                setToggleInfoWindow(false);
                let type = ['CCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (!isDisabled && updatedSelectedmarket.length >= 1 && showCCC && !showMCC && !showLCC) {
                setToggleInfoWindow(false);
                let type = ['CCC'];
                handleGetLCCMCCData(updatedSelectedmarket, type);
                handleVehicleCountMCC(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
            if (!isDisabled && updatedSelectedmarket.length >= 1 && !showMCC && !showLCC && !showCCC) {
                setToggleInfoWindow(true);
                handleSearchToggle(updatedSelectedmarket);
                handleCloseMCCLCCTable();
            };
        }
        setSelected(updatedSelected);
        setSelectedMarkets(updatedSelectedmarket);
    };

    return (
        <Card id="CardBodyCardAllowance4" style={{ marginBottom: selectTable ? '40px' : '' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "calc(9px + 0.390625vw)", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', textWrap: 'nowrap' }}>MARKET</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white', marginTop: '-15px' }}>
                    {
                        markets.map((item) => {
                            return (
                                <tr>
                                    <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '30px' }}>{item.value}</td> <br />
                                    <td style={{ float: 'right', }}>
                                        {
                                            multiMarketSelect ?
                                                <ToggleComponent checked={selected.indexOf(item.key) > -1} disabled={checkedFlag.indexOf(item.key) > -1} onChange={handleChange(item.key, selected.indexOf(item.key) > -1)} />
                                                :
                                                <ToggleComponent checked={!(selected.indexOf(item.key) > -1) && !(checkedFlag.indexOf(item.key) > -1)} disabled={(checkedFlag.indexOf(item.key) > -1)} onChange={handleChangeMap(item.key, selected.indexOf(item.key) > -1)} />
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
            </CardBody>
        </Card>
    )
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    graphData: selectUserGraphData(state),
    userParentOrg: selectUserParentOrganization(state)
});

export default connect(mapStateToProps, null)(MarketCard);