import { LogLevel } from "@azure/msal-browser";
import AppSettings from 'src/AppSettings.json';

export const msalConfig = {
    auth: {
        clientId: AppSettings.config["clientId"],
        authority: AppSettings.config["adAuthority"],
        // redirectUri: AppSettings.config["redirectUri"]
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            logLevel: LogLevel.Verbose,
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		    
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:
                        console.error(message);		
                        return;
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};

export const profileRequest = {
    scopes: ["User.Read", "profile"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMePhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value'
};