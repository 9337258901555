import { createSelector } from "reselect";

const LoggedInUser = state => state.user;

export const selectIsUserAuthenticated = createSelector(
    [LoggedInUser],
    user => user.adUserDetails.isAuthenticated
)

export const selectUser = createSelector(
    [LoggedInUser],
    user => user
)

export const selectUserEmail = createSelector(
    [LoggedInUser],
    user => user.adUserDetails.email
)

export const selectUserGraphData = createSelector(
    [LoggedInUser],
    user => user.adUserDetails.graphData
)

export const selectAdUserDetails = createSelector(
    [LoggedInUser],
    user => user.adUserDetails
)

export const selectUserMarketIds = createSelector(
    [LoggedInUser],
    user => user.userServiceDetails.marketIds
)

export const selectUserOrganization = createSelector(
    [LoggedInUser],
    user => user.userServiceDetails.organization
)

export const selectUserParentOrganization = createSelector(
    [LoggedInUser],
    user => user.userServiceDetails.parentOrganization
)

export const selectUserViewOrganization = createSelector(
    [LoggedInUser],
    user => user.userServiceDetails.viewOrganization
)

export const selectUserRoles = createSelector(
    [LoggedInUser],
    user => user.userServiceDetails.role
)

export const selectUserPermissions = createSelector(
    [LoggedInUser],
    user => user.userServiceDetails.permissions
)

export const selectUserToken = createSelector(
    [LoggedInUser],
    user => user.userServiceDetails.token
)

export const selectRefreshToken = createSelector(
    [LoggedInUser],
    user => user.userServiceDetails.refreshToken
)

export const selectTokenExpiry = createSelector(
    [LoggedInUser],
    user => user.userServiceDetails.tokenExpiry
)