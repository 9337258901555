import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import ReactTableBaseVehiclePageCashCars from 'src/components/tables/newtable/table/ReactTableBaseCashCarsVehiclePage';
import { makeStyles } from '@material-ui/core/styles';
import { SearchByExtendedSearch } from 'src/services/vehicle-pages-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { GetFleets } from 'src/services/fleet-http.service'
import { GetFleetTypes } from 'src/services/fleetType-http.service'
import { FormmatDate } from 'src/utils/DateFormatter';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';
import { Typography } from '@material-ui/core';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import CSVExport from 'src/services/csv-export.service';
import Modal from "@mui/material/Modal";
import SearchButton from 'src/components/buttons/search-button-component';
import './AllCarsList.css';
import FormSelect from 'src/components/form-elements/custom-select.component';
import { GetVehicleModelYears } from 'src/services/vehicle-pages-http.service';
import { GetOrderStatusList } from 'src/services/vehicle-pages-http.service';
import Fade from 'react-reveal/Fade';
import SearchField from 'src/components/buttons/search-icon-button-new.component';
import magnifyicon_hover from 'src/assets/img/vehicles/magnifyicon_hover.png';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { GetOrganizationById } from "src/services/organization-http.service";

let rowsPerPage = 200;
let newPageVariable = 1;
let otdFleetType = 'No';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 2,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 580,
        display: 'flex'
    },
    drawarModal: {
        width: '100%',
        flexShrink: 0,
        marginTop: '150px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    }
}));

const CashCarsTable = ({ userOrg, userMarketIds, inputVIN, soldcarstable = true, searchInputVIN, value, isEnableSave }) => {

    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [selected, setSelected] = useState('');
    const [open, setOpen] = useState(false);

    const [rows, setVehicleFleet] = useState([]);

    const [presSelectedFleetTypes, setPresSelectedFleetTypes] = useState([]);

    let selectedRowVehiclePostion = {};

    let marketIds = [];

    let fleetTypes = [];

    const [dataLoaded, setDataLoaded] = useState(false);

    const [selectedFleetType, setSelectedFleetType] = useState({ key: "NONE", value: "" });

    const [isColumnHidden, setIsColumnHidden] = useState(false);
    const drawerClasses = useDrawerStyles();
    const [shouldRenderMap, setShouldRenderMap] = useState(false)
    const [vehiclePosition, setVeiclePosition] = useState({
        "vin": "",
        "latitude": 0,
        "longitude": 0,
        "altitude": 0,
        "canBeTrusted": false,
        "vehicleUpdatedAt": ""
    });

    const [queryParamsAge, setQueryParamsAge] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })

    const [queryParamsMarket, setQueryParamsMarket] = useState('');
    const [queryParamsFleetType, setQueryParamsFleetType] = useState('');
    const [queryParamsVin, setQueryParamsVin] = useState('');
    const [queryParamsLicensePlate, setQueryParamsLicensePlate] = useState('');
    const [queryParamsOrderType, setQueryParamsOrderType] = useState(['B2B', 'B2C', '']);
    const [queryParamsPNOCode, setQueryParamsPNOCode] = useState('');
    const [queryParamsOrderStatus, setQueryParamsOrderStatus] = useState([]);
    const [queryParamsOrderNumber, setQueryParamsOrderNumber] = useState('');
    const [queryParamsModel, setQueryParamsModel] = useState('');
    const [queryParamsEngine, setQueryParamsEngine] = useState(['HEV', 'PHEV']);
    const [queryParamsColor, setQueryParamsColor] = useState(['Blue', 'Black']);
    const [queryParamsRvBelowLandedCost, setQueryParamsRvBelowLandedCost] = useState('');
    const [queryParamsCcWithin3Months, setQueryParamsCcWithin3Months] = useState('');
    const [queryParamsUsedCarStock, setQueryParamsUsedCarStock] = useState('');
    const [queryParamsModelYear, setQueryParamsModelYear] = useState([]);

    const [queryParamsAvailableForSales, setQueryParamsAvailableForSales] = useState(false);
    const [queryParamsCommercialCostMonthIndex, setQueryParamsCommercialCostMonthIndex] = useState('');

    const [queryParamsURvRange, setQueryParamsRvRange] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    });
    const [queryParamsUsedInventoryStatus, setQueryParamsInventoryStatus] = useState('');
    const [queryParamsLastMileStatus, setQueryParamsLastmileStatus] = useState('');
    const [queryParamsProvisionStatus, setQueryParamsProvisionStatus] = useState('');
    const [queryParamsMovementStatus, setQueryParamsMovementStatus] = useState('');

    const [showSearchBar, setShowSearchBar] = useState(false);

    const imgsMagnifyIcon = [magnifyIcon, magnifyicon_hover];

    const [iconMagnify, setIconMagnify] = useState(imgsMagnifyIcon[0]);
    const [openIconMagnify, setOpenIconMagnify] = useState(false);

    const [counterPagination, setCounterPagination] = useState(1);
    const [disablePagination, setDisablePagination] = useState(false);

    const [toggleSearchBar, setToggleSearchBar] = useState(false);

    const columns = useMemo(
        () => [
            { Header: 'License Plate', accessor: "licensePlate", disableGlobalFilter: true },
            { Header: 'VIN', accessor: "vin", disableGlobalFilter: true },
            { Header: 'PNO', accessor: "pnoCode", disableGlobalFilter: true },
            { Header: 'Model', accessor: "model", disableGlobalFilter: true, },
            { Header: 'Model Year', accessor: "modelYear", disableGlobalFilter: true },
            { Header: 'Engine Type', accessor: "engineType", disableGlobalFilter: true, },
            { Header: 'OBC', accessor: "obcVersion", disableGlobalFilter: true, },
            { Header: 'Color', accessor: "color", disableGlobalFilter: true, },
            { Header: 'Towbar', accessor: "hasTowbar", disableGlobalFilter: true },
            { Header: 'RV [when sold]', accessor: "rvWhenSold", disableGlobalFilter: true },
            { Header: 'Age [when sold]', accessor: "ageWhenSold", disableGlobalFilter: true },
            { Header: 'Odometer', accessor: "odometerKm", disableGlobalFilter: true },
            { Header: 'Registered', accessor: "registeredDateUtc", disableGlobalFilter: true },
            { Header: 'Market', accessor: "market", disableGlobalFilter: true },
            { Header: 'Order Type', accessor: "orderType", disableGlobalFilter: true },
            { Header: 'Order Number', accessor: "orderNumber", disableGlobalFilter: true }
        ],
        [],
    );

    let queryParams = {}

    useEffect(() => {
        if (totalItems <= rows.length) {
            setDisablePagination(true);
        }
    }, [rows.length, totalItems]);

    useEffect(() => {
        setModelYears();
        setOderStatus();
        if (queryParamsModelYear.length !== 0 && queryParamsOrderStatus.length !== 0) {
            initializeFleetVehicles();
        }
    }, [queryParamsModelYear.length, queryParamsOrderStatus.length]);

    const setModelYears = () => {
        let modelYearArray = [];

        GetVehicleModelYears().then(data => {
            Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

                modelYear => {
                    modelYearArray.push(
                        modelYear.value
                    );
                })
                : console.log("Error Occured");
            setQueryParamsModelYear(modelYearArray);
        });
    };

    const setOderStatus = () => {
        let orderStatusArray = [];

        GetOrderStatusList().then(data => {
            Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

                orderStatus => {
                    orderStatusArray.push(
                        orderStatus.value
                    );
                })
                : console.log("Error Occured");
            setQueryParamsOrderStatus(orderStatusArray);
        });
    };

    const initializeFleetVehicles = () => {
        let fleetTypes = [];
        let fleetIds = [];
        let vehicleFleetArray = [];
        let marketIds = userMarketIds;
        newPageVariable = 1;
        setDisablePagination(false);
        setCounterPagination(0);
        setShowSearchBar(false);
        setToggleSearchBar(false);
        setQueryParamsVin('');

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: '' }
        queryParams = { ...queryParams, licensePlate: '' }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, belowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, ccWithin3Months: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, movementStatus: queryParamsMovementStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                });
                            };

                            SearchByExtendedSearch({ ...queryParams, pageNumber: newPageVariable, pageSize: rowsPerPage, listViewType: 3, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages)
                                        setTotalItems(data.totalItems ?? 0)
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push(
                                                {
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    pnoCode: vehicle.pnoCode,
                                                    model: vehicle.model,
                                                    modelYear: vehicle.modelYear,
                                                    engineType: vehicle.engineType,
                                                    obcVersion: vehicle.obcVersion,
                                                    color: vehicle.color,
                                                    hasTowbar: vehicle.hasTowbar,
                                                    rvWhenSold: formatAndVerifyNumericValue(vehicle.rvWhenSold),
                                                    ageWhenSold: vehicle.ageWhenSold,
                                                    odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                    registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                    market: vehicle.market,
                                                    orderType: vehicle.orderType,
                                                    orderNumber: vehicle.orderNumber
                                                }
                                            );
                                        });
                                    };
                                    setDataLoaded(true);
                                    setVehicleFleet(vehicleFleetArray);
                                });
                        });
                };
            });
    };

    const PaginationFleetVehicles = (marketIds, page) => {
        let fleetTypes = [];
        let fleetIds = [];
        let vehicleFleetArray = [];

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, belowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, ccWithin3Months: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, movementStatus: queryParamsMovementStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }


        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                                setPresSelectedFleetTypes(fleetTypes)
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: page, pageSize: rowsPerPage, listViewType: 3, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType })
                                .then(data => {

                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push(
                                                {
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    pnoCode: vehicle.pnoCode,
                                                    model: vehicle.model,
                                                    modelYear: vehicle.modelYear,
                                                    engineType: vehicle.engineType,
                                                    obcVersion: vehicle.obcVersion,
                                                    color: vehicle.color,
                                                    hasTowbar: vehicle.hasTowbar,
                                                    rvWhenSold: formatAndVerifyNumericValue(vehicle.rvWhenSold),
                                                    ageWhenSold: vehicle.ageWhenSold,
                                                    odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                    registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                    market: vehicle.market,
                                                    orderType: vehicle.orderType,
                                                    orderNumber: vehicle.orderNumber
                                                }
                                            );
                                        });
                                    }
                                    setVehicleFleet([...rows, ...vehicleFleetArray]);
                                });
                        });
                };
            });
    };

    const header = [

        [
            'License Plate',
            'VIN',
            'PNO',
            'Model',
            'Model Year',
            'Engine Type',
            'OBC',
            'Color',
            'Towbar',
            'RV [when sold]',
            'Age [when sold]',
            'Odometer',
            'Registered',
            'Market',
            'Order Type',
            'Order Number'
        ]
    ];

    const handleClickDownloadExcel = () => {
        setDownloadSpinner(true);
        if (!inputVIN) {
            inputVIN = queryParamsVin;
        };

        let fleetTypes = [];
        let fleetIds = [];
        let vehicleFleetArray = [];
        let marketIds = userMarketIds;

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: inputVIN.trim() }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, belowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, ccWithin3Months: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, movementStatus: queryParamsMovementStatus }

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 0, pageSize: 0, listViewType: 3, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                rvWhenSold: vehicle.rvWhenSold,
                                                ageWhenSold: vehicle.ageWhenSold,
                                                odometerKm: vehicle.odometerKm,
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                market: vehicle.market,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })
                }
            })

        const initiateDownload = (rows) => {
            const fileName = 'Sold Cars';

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            const fileExtension = '.xlsx';
            const exportToExcel = (rows, fileName) => {

                const ws = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(ws, header);

                XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });

                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, fileName + fileExtension);

            }
            exportToExcel(rows, fileName);
        }
        setOpenModal(false);
    }

    const handleClickDownloadCSV = () => {
        setDownloadSpinner(true);
        if (!inputVIN) {
            inputVIN = queryParamsVin
        }

        let fleetTypes = []
        let fleetIds = []
        let vehicleFleetArray = []
        let marketIds = userMarketIds;

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: inputVIN.trim() }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, belowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, ccWithin3Months: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, movementStatus: queryParamsMovementStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 0, pageSize: 0, listViewType: 3, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                rvWhenSold: vehicle.rvWhenSold,
                                                ageWhenSold: vehicle.ageWhenSold,
                                                odometerKm: vehicle.odometerKm,
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                market: vehicle.market,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })
                }
            })

        const initiateDownload = (rows) => {
            CSVExport(header, rows, 'Sold Card')
        }

        setOpenModal(false);

    };

    const handleCloseDownload = () => {
        setOpenModal(false);
    };

    const handleChangePage = (newPage) => {
        newPageVariable = newPageVariable + 1;
        setCounterPagination(newPageVariable);
        marketIds = userMarketIds
        PaginationFleetVehicles(marketIds, newPageVariable);
        setOpen(false);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const handleDrawar = () => {
        let vin = selectedRowVehiclePostion.vin;
        setOpen(true);
        setShouldRenderMap(true);
        setToggleSearchBar(!toggleSearchBar);
    }

    const closeHandleDrawer = () => {
        setOpen(false);
        setToggleSearchBar(!toggleSearchBar);
    }

    const handleExtendedSearch = () => {
        setDataLoaded(false);

        setShowSearchBar(true);
        setToggleSearchBar(true);

        if (!inputVIN) {
            inputVIN = queryParamsVin;
        };

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: inputVIN.trim() }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        console.log(queryParams)

        fleetTypes = selectedFleetType.value == '' ? presSelectedFleetTypes : selectedFleetType.value;
        let fleetIds = []
        let vehicleFleetArray = [];
        marketIds = userMarketIds;

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 1, pageSize: rowsPerPage, listViewType: 3, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages)
                                        setTotalItems(data.totalItems ?? 0)
                                        newPageVariable = data.currentPage;
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                rvWhenSold: formatAndVerifyNumericValue(vehicle.rvWhenSold),
                                                ageWhenSold: vehicle.ageWhenSold,
                                                odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                market: vehicle.market,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    if (data.totalPages == 0) {
                                        newPageVariable = 1
                                    }
                                    setVehicleFleet(vehicleFleetArray);
                                    setDataLoaded(true);
                                });
                        });
                };
            });
    };

    const haandleDropdown = (e) => {
        const { name, value } = e.target;
        if (value == 1) {
            handleOpenDownload()
        };
    };

    const handleOpenDownload = () => {
        setDownloadSpinner(false);
        setOpenModal(true);
    };

    const handleSearchInput = (event) => {
        setQueryParamsVin(event.target.value);
        console.log(event.target.value);
        console.log(event.target.value.length);
    };

    const textFieldKeypressSearch = (e) => {
        if (e.key === 'Enter') {
            handleExtendedSearch();
        };
    };

    const handleCloseSearchbar = () => {
        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
    };

    const handleSearchbar = () => {
        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
        setDataLoaded(false);
        setQueryParamsVin('');

        if (!inputVIN) {
            inputVIN = queryParamsVin
        };

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: '' }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        console.log(queryParams)

        fleetTypes = selectedFleetType.value == '' ? presSelectedFleetTypes : selectedFleetType.value;
        let fleetIds = []
        let vehicleFleetArray = [];
        marketIds = userMarketIds;

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 1, pageSize: rowsPerPage, listViewType: 3, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages)
                                        setTotalItems(data.totalItems ?? 0)
                                        newPageVariable = data.currentPage;
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                rvWhenSold: formatAndVerifyNumericValue(vehicle.rvWhenSold),
                                                ageWhenSold: vehicle.ageWhenSold,
                                                odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                market: vehicle.market,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    if (data.totalPages == 0) {
                                        newPageVariable = 1
                                    }
                                    setVehicleFleet(vehicleFleetArray);
                                    setDataLoaded(true);
                                })
                        })
                }
            })
    };

    const handleCloseMagnifyIcon = () => {

        setIconMagnify(imgsMagnifyIcon[0]);
        setOpenIconMagnify(false);
    };

    const handleOpenMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[1]);
        setOpenIconMagnify(true);
    };

    return (
        <Col md={12} lg={12}>
            <Modal
                className={drawerClasses.drawarModal}
                variant="persistent"
                anchor="right"
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleCloseDownload}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <div style={{
                    border: 'none', height: '170px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px'
                }}>
                    <Typography style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", marginTop: '-80px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} variant="h4" id="tableTitle" component="div">
                        Select format
                    </Typography>
                    <Row className='downloadstyle'>
                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Excel'}
                                handleSearch={() => handleClickDownloadExcel(rows)}
                                height='true'
                                loader='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'CSV'}
                                handleSearch={() => handleClickDownloadCSV(rows)}
                                height='true'
                                loader='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Cancel'}
                                handleSearch={handleCloseDownload}
                                height='true'
                                rsa='true'
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>

            <div style={{ marginTop: '87px', marginRight: '-4px', marginLeft: '-17px' }}>
                <div id='tablecard' >
                    <Row className='headerStyleMyOpenCasesLCAssets'>
                        <Col lg="8" md="8" sm="8" xs="8" ></Col>
                        <Col lg="4" md="4" sm="4" xs="4" >
                            {
                                <Row className='rowheightLCAssets'>
                                    <Col lg="4" md="4" sm="4" xs="4" >
                                        {
                                            <Row>
                                                <Col lg="4" md="4" sm="4" xs="4">
                                                    {
                                                        <>
                                                            {
                                                                showSearchBar ?
                                                                    <div className='searchStyleULCAseets'>
                                                                        <SearchField
                                                                            handleSearchbar={handleSearchbar}
                                                                            placeholder='VIN or License Plate'
                                                                            handleSearch={handleExtendedSearch}
                                                                            handleSearchInput={handleSearchInput}
                                                                            onKeyPress={textFieldKeypressSearch}
                                                                            value={queryParamsVin}
                                                                            priceBucketActiveTab='true'
                                                                            autoFocus='true'
                                                                        />
                                                                    </div>
                                                                    :

                                                                    <Tooltip
                                                                        title="open search"
                                                                        onOpen={handleOpenMagnifyIcon}
                                                                        onClose={handleCloseMagnifyIcon}
                                                                        style={{ zIndex: 200 }}
                                                                    >
                                                                        <IconButton
                                                                            onClick={handleCloseSearchbar}
                                                                        >
                                                                            <img
                                                                                src={iconMagnify}
                                                                                className="magnifyIconLCAsset"
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                            }
                                                            <div className='optionsSelectLCAssets'>
                                                                {

                                                                    <FormSelect
                                                                        menuObjectArray={[
                                                                            { 'key': '1', 'value': 'Download' }
                                                                        ]}
                                                                        onClick={haandleDropdown}
                                                                    />
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </Col >
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                            }
                        </Col>
                        {
                            <Fade bottom duration={1500}>
                                <div className="tableFixHeadLCAssets">
                                    <Row>
                                        <Col md={12} sm={12} xs={12} lg={12}>

                                            <>
                                                <ReactTableBaseVehiclePageCashCars
                                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                                    columns={columns}
                                                    data={rows}
                                                    updateEditableData={updateEditableData}
                                                    updateDraggableData={updateDraggableData}
                                                    tableConfig={tableConfig}
                                                    isColumnHidden={isColumnHidden}
                                                    isSelected={isSelected}
                                                    handleDrawar={handleDrawar}
                                                    closeHandleDrawer={closeHandleDrawer}
                                                    vehiclePosition={vehiclePosition}
                                                    selected={selected}
                                                    soldcarstable={soldcarstable}
                                                    handleChangePage={() => handleChangePage(counterPagination)}
                                                    disablePagination={disablePagination}
                                                />
                                            </>

                                        </Col>
                                    </Row>
                                </div>
                                <div className='paginationUndefined' style={{ fontFamily: 'LynkcoTypeRegular' }}>Total Records: {formatAndVerifyNumericValue(totalItems)}</div>
                            </Fade>
                        }
                    </Row>
                </div>
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(CashCarsTable);