import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import ReactTableBase from 'src/components/tables/newtable/table/ReactTableBase';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormmatDate } from 'src/utils/DateFormatter';
import EnhancedTableToolbarInternalFleet from 'src/components/tables/table-toolbar-Internal.component';
import AlertDialog from 'src/components/form-elements/dialog-window.component';
import Drawer from '@material-ui/core/Drawer';
import InfleetingAddEdit from 'src/components/form-elements/infleeting-add-edit.component';
import InternalFleetUpload from 'src/components/form-elements/internal-fleet-upload.component'
import { GetFleetVehicles, CreateFleetVehicle, UpdateFleetVehicle, DefleteFleetVehicle } from 'src/services/Infleeting-http-service';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions, selectUserParentOrganization } from 'src/redux/user/user.selectors';
import { GetFleets } from 'src/services/fleet-http.service';
import { QueryLicensePlateOrVin } from 'src/services/vehicle-pages-http.service';
import { GetFleetTypes } from 'src/services/fleetType-http.service';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { permission } from 'src/auth/permissions';
import Fade from 'react-reveal/Fade';
import SearchField from 'src/components/buttons/search-icon-button-new.component';
import magnifyicon_hover from 'src/assets/img/vehicles/magnifyicon_hover.png';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { GetOrganizationById } from "src/services/organization-http.service";

let rowsPerPage = 200;
let newPageVariable = 1;
const drawerWidth = 240;

const useDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: '-15px'
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        "&.MuiDrawer-paperAnchorDockedRight": {
            top: '160px'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        height: 'max-content',
        "&.MuiDrawer-paperAnchorDockedRight": {
            top: '9%',
            right: '45px'
        }
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    SwipeableDrawerRvCurve: {
        height: '52%',
        width: '20%',
        "&.MuiDrawer-paperAnchorRight": {
            top: '80px',
            right: '50px'
        }
    },
}));

const InternalFleetsTable = ({ userOrg, userParentOrg, userMarketIds, userPermissions }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = useState('');
    const [open, setOpen] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const drawerClasses = useDrawerStyles();
    const [page, setPage] = useState(0);
    const [rows, setVehicleFleet] = useState([]);
    const [fleetIds, setFleetIds] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [isErrorDialog, setErrorDialog] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [licensePlateOrVinInput, setLicensePlateOrVinInput] = useState('')

    const [fleetVehicle, setFleetVehicle] = useState({
        licensePlate: "",
        vin: "",
        fleet: "",
        fleetId: "",
        fleetType: "",
        fleetTypeId: "",
        market: "",
        marketId: "",
        infleetedDateUtc: "",
        editMode: false
    });

    const [searchInput, setSearchInput] = useState('');

    const [showSearchBar, setShowSearchBar] = useState(false);
    const imgsMagnifyIcon = [magnifyIcon, magnifyicon_hover];

    const [iconMagnify, setIconMagnify] = useState(imgsMagnifyIcon[0]);
    const [openIconMagnify, setOpenIconMagnify] = useState(false);
    const [toggleSearchBar, setToggleSearchBar] = useState(false);
    const [enableSelect, setEnableSelect] = useState(true);

    const columns = useMemo(
        () => [
            { Header: 'License Plate', accessor: "licensePlate", disableGlobalFilter: true, },
            { Header: 'VIN', accessor: "vin", disableGlobalFilter: true, },
            { Header: 'Fleet Type', accessor: "fleetType", disableGlobalFilter: true, },
            { Header: 'Market', accessor: "market", disableGlobalFilter: true, },
            { Header: 'Infleeting date', accessor: "infleetedDateUtc", disableGlobalFilter: true, }
        ],
        [],
    );

    const [selectedRows, setSelectedRows] = useState({ "0": true, "9": true });

    useEffect(() => {
        initializeFleetVehicles();
        setOpenDialog(false);
    }, []);

    useEffect(() => {
        if (searchInput?.length === 0) {
            initializeFleetVehicles();
        }

    }, [searchInput]);

    const initializeFleetVehicles = () => {
        let fleetIdArray = [];
        let vehicleFleetArray = [];

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data.viewFleetIds.length > 0) {
                    data.viewFleetIds.map(item => {
                        fleetIdArray.push(item);
                    })};

                GetFleetVehicles({ pageNumber: newPageVariable, pageSize: rowsPerPage, fleetIdArray: fleetIdArray, marketIds: userMarketIds })
                    .then(data => {
                        if (data && Array.isArray(data.items)) {
                            setTotalPages(data.totalPages)
                            data.items.map(vehicle => {
                                vehicleFleetArray.push(
                                    {
                                        licensePlate: vehicle.licensePlate,
                                        vin: vehicle.vin,
                                        fleetType: vehicle.fleetType,
                                        fleetTypeId: vehicle.fleetTypeId,
                                        fleet: vehicle.fleet,
                                        fleetId: vehicle.fleetId,
                                        market: vehicle.market,
                                        infleetedDateUtc: FormmatDate(vehicle.infleetedDateUtc)
                                    }
                                );
                            });
                        }
                        setVehicleFleet(vehicleFleetArray);
                    });
                setFleetIds(fleetIdArray);
            });
    };

    const handleClickAdd = () => {
        setOpen(true);
        setFleetVehicle({
            ...fleetVehicle, licensePlate: "", market: "", vin: "",
            fleetId: "", fleetTypeId: "", fleet: "", fleetType: "", infleetedDateUtc: "", editMode: false
        });

    };

    const handleClickEdit = () => {
        if (selectedRow !== null) {
            setFleetVehicle({
                ...fleetVehicle, licensePlate: selectedRow.licensePlate,
                market: selectedRow.market, vin: selectedRow.vin,
                fleetId: selectedRow.fleetId, fleetTypeId: selectedRow.fleetTypeId,
                fleet: selectedRow.fleet, fleetType: selectedRow.fleetType,
                infleetedDateUtc: selectedRow.infleetedDateUtc, editMode: true
            });
            setOpen(true);
        }
    };

    const handleDeleteDialogContinue = () => {
        DefleetFleetVehicle(selected);
    }
    const handleDeleteDialogCancel = () => {
        setOpenDialog(false)
        setSelected('');
    }
    const handleClickDelete = () => {
        if (selected.length !== 0) {
            setErrorDialog(false)
            setOpenDialog(true);
            setDialogMessage(`Are you sure you want to defleet ${selectedRow.licensePlate} ?`)
        }
    };
    const handleDialogOk = () => {
        setOpenDialog(false)
    }

    const DefleetFleetVehicle = (selectedValue) => {
        if (selectedValue.vin === selectedRow.vin) {
            DefleteFleetVehicle({
                "vin": selectedValue.vin,
                "fleetIds": fleetIds,
                "marketIds": userMarketIds
            }).then(data => {
                initializeFleetVehicles();
                setSelected('');
                setOpenDialog(false);
                setEnableSelect(true);
            })
        }
    };

    const saveFleetVehicle = () => {
        fleetVehicle.editMode ?
            UpdateFleetVehicle({
                "vin": fleetVehicle.vin,
                "fleetTypeId": fleetVehicle.fleetTypeId,
                "fleetIds": fleetIds,
                "marketIds": userMarketIds
            })


                .then(data => {
                    initializeFleetVehicles();
                    setSelected('');
                    resetFleetVehicle();
                    if (data.statusCode === 409) {
                        setDialogMessage(data.errorMessage)
                        setOpenDialog(true)
                        setErrorDialog(true)
                    }
                })
            :
            CreateFleetVehicle({
                "vin": fleetVehicle.vin,
                "fleetTypeId": null,
                "fleetId": fleetVehicle.fleetId,
                "marketIds": userMarketIds,

            })
                .then(data => {
                    initializeFleetVehicles();
                    setSelected('');
                    resetFleetVehicle();
                    if (data.statusCode === 409) {
                        setDialogMessage(data.errorMessage)
                        setOpenDialog(true)
                        setErrorDialog(true)
                    }
                });
    };

    const handleClose = () => {
        setOpen(false);
        setEnableSelect(false);
    };

    const handleCloseUpload = () => {
        setOpenUpload(false);
    };

    const handleDone = () => {
        setOpen(false);
        saveFleetVehicle();
        setEnableSelect(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFleetVehicle({ ...fleetVehicle, [name]: value });
    };

    const handleClick = (event, name, row) => {
        let newSelected = selected;
        if (name !== selected) {
            newSelected = name;
        }
        setSelected(newSelected);
        setSelectedRow({
            licensePlate: row.licensePlate,
            vin: row.vin,
            fleetType: row.fleetType,
            fleetTypeId: row.fleetTypeId,
            fleet: row.fleet,
            fleetId: row.fleetId,
            infleetedDateUtc: row.infleetedDateUtc,
            market: row.market
        });
        setOpen(false);
    };

    const resetFleetVehicle = () => {
        setFleetVehicle({
            licensePlate: "",
            vin: "",
            fleetType: "",
            fleetTypeId: "",
            fleet: "",
            fleetId: "",
            infleetedDateUtc: "",
            market: "",
            editMode: false
        });
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const handleOpenUpload = () => {
        setOpenUpload(true);
    };

    const selectedRowValues = (row) => {
        setSelectedRow({
            licensePlate: row.original.licensePlate,
            vin: row.original.vin,
            fleetType: row.original.fleetType,
            fleetTypeId: row.original.fleetTypeId,
            fleet: row.original.fleet,
            fleetId: row.original.fleetId,
            market: row.original.market,
            infleetedDateUtc: FormmatDate(row.original.infleetedDateUtc)
        });
        setSelected({
            licensePlate: row.original.licensePlate,
            vin: row.original.vin,
            fleetType: row.original.fleetType,
            fleetTypeId: row.original.fleetTypeId,
            fleet: row.original.fleet,
            fleetId: row.original.fleetId,
            market: row.original.market,
            infleetedDateUtc: FormmatDate(row.original.infleetedDateUtc)
        })

    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };

    /////////////////////////////////// Search ////////////////////////////////////

    const handleSearchInput = (event) => {
        setSearchInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInput('');
        }
    };

    const handleSearch = () => {
        if (searchInput.trim()?.length != 0) {

            let fleetTypes = []
            let fleetIds = []
            let vehicleFleetArray = [];
            let marketIds = userMarketIds;

            GetFleets(userOrg.id, userMarketIds)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })
                        GetFleetTypes(userOrg.id)
                            .then(data => {
                                if (data && Array.isArray(data)) {
                                    data.map(fleetType => {
                                        fleetTypes.push(fleetType['id'])
                                    })
                                }

                                QueryLicensePlateOrVin(searchInput.trim(), fleetIds, fleetTypes, marketIds)
                                    .then(data => {
                                        if (data && Array.isArray(data)) {
                                            data.map(vehicle => {
                                                vehicleFleetArray.push({
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    fleetType: vehicle.fleetType,
                                                    fleetTypeId: vehicle.fleetTypeId,
                                                    fleet: vehicle.fleet,
                                                    fleetId: vehicle.fleetId,
                                                    market: vehicle.market,
                                                    infleetedDateUtc: FormmatDate(vehicle.infleetedDateUtc)
                                                });
                                            });
                                        }
                                        setVehicleFleet(vehicleFleetArray);
                                    });
                            })
                    }
                })

            setLicensePlateOrVinInput('')
        }

    };

    const anchor = "right";
    const slider = (anchor) => (
        <div role="presentation">
            <InternalFleetUpload orgId={userParentOrg.id}
                onUploadComplete={initializeFleetVehicles}
                handleCloseUpload={handleCloseUpload}
                fleetVehicle={fleetVehicle}
                onChange={handleChange} >
            </InternalFleetUpload>
        </div>
    );

    const handleUpload = () => {
        setOpenUpload(true);
    };

    const handleCloseMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[0]);
        setOpenIconMagnify(false);
    };

    const handleOpenMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[1]);
        setOpenIconMagnify(true);
    };

    const handleCloseSearchbar = () => {
        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
        initializeFleetVehicles();
    };

    const handleSearchbar = () => {
        setShowSearchBar(false);
        setToggleSearchBar(false);
        initializeFleetVehicles();
        setSearchInput('');
    };

    const matchesPermission = userPermissions.includes(permission.ManageAsset_Administrator);

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Col md={12} lg={12}>
            <AlertDialog
                open={openDialog}
                message={dialogMessage}
                handleDeleteDialogCancel={handleDeleteDialogCancel}
                handleDeleteDialogContinue={handleDeleteDialogContinue}
                handleDialogOk={handleDialogOk}
                isErrorDialog={isErrorDialog}
            />

            <Drawer
                className={drawerClasses.drawer}
                variant="permanet"
                anchor="right"
                open={open}
                onClose={handleClose}
                classes={{
                    paper: drawerClasses.drawerPaper,
                }}>
                <InfleetingAddEdit orgId={userOrg ? userParentOrg.id : ""} fleetVehicle={fleetVehicle} onChange={handleChange}
                    handleClose={handleClose} handleDone={handleDone}></InfleetingAddEdit>
            </Drawer>

            <SwipeableDrawer
                classes={{
                    paper: drawerClasses.SwipeableDrawerRvCurve,
                }}
                anchor={anchor}
                open={openUpload}
                onClose={handleCloseUpload}
                onOpen={handleOpenUpload}
            >
                {slider(anchor)}
            </SwipeableDrawer>

            <div id="tablecardRolePage">
                <Row className='headerStyleInternalAssets'>
                    <Col lg="6" md="6" sm="6" xs="6" ></Col>
                    <Col lg="2" md="2" sm="2" xs="2">
                        {
                            showSearchBar ?
                                <>
                                    <div className='searchStyleInternalAssets'>
                                        <SearchField
                                            handleSearchbar={handleSearchbar}
                                            handleSearch={handleSearch}
                                            handleSearchInput={handleSearchInput}
                                            onKeyPress={textFieldKeypress}
                                            value={searchInput}
                                            priceBucketActiveTab='true'
                                            autoFocus='true'
                                            placeholder={'License Plate or VIN'}
                                        />
                                    </div>
                                </>
                                :
                                <Tooltip
                                    title="open search"
                                    onOpen={handleOpenMagnifyIcon}
                                    onClose={handleCloseMagnifyIcon}
                                    style={{ zIndex: 200 }}
                                >
                                    <IconButton
                                        onClick={handleCloseSearchbar}
                                    >
                                        <img
                                            src={iconMagnify}
                                            className="magnifyIconInternalAssets"
                                        />
                                    </IconButton>
                                </Tooltip>
                        }
                    </Col >

                    <Col lg="2" md="2" sm="2" xs="2" className='columnstyleInternalAssets'>
                        {
                            matchesPermission &&
                            <div className='toolbarstyleInternalAssets'>
                                <EnhancedTableToolbarInternalFleet
                                    selectedRowId={selected}
                                    handleClickDelete={handleClickDelete}
                                    handleClickEdit={handleClickEdit}
                                    handleClickAdd={handleClickAdd}
                                    handleUpload={handleUpload}
                                    enableSelect={enableSelect}
                                />
                            </div>
                        }
                    </Col>

                    <Col lg="2" md="2" sm="2" xs="2"></Col>
                </Row>

                <>
                    <Fade bottom duration={1500}>
                        <div className="tableFixHeadAdmin">
                            {
                                <ReactTableBase className='table-hover'
                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                    columns={columns}
                                    data={rows}
                                    tableConfig={tableConfig}
                                    onClick={(event) => handleClick(event, rows.email, rows)}
                                    onRowSelected={handleClick}
                                    isSelected={isSelected}
                                    selectedRowValues={selectedRowValues}
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    setEnableSelect={setEnableSelect}
                                />
                            }
                        </div>
                    </Fade>
                </>
            </div>

        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state),
    userParentOrg: selectUserParentOrganization(state)
});

export default connect(mapStateToProps, null)(InternalFleetsTable);