import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";
import { loginRequest } from "src/authConfig";
import { GetToken } from 'src/auth/userToken';

export async function QueryUserByEmail(email, appId, isAuthRequest = false) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    let token = await GetToken();

    if (isAuthRequest) {
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        token = `Bearer ${response.accessToken}`
    }

    const headers = new Headers();
    const bearer = token;

    headers.append("CustomAuthorization", bearer); 
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "email": email,
            "appId": appId
        })
    };

    let apiData = await fetch(`${environment.backendUserService}/api/User/QueryUserByEmail`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));

    return apiData;

}


///////////////////// TO reauthorise user ////////////////////////////


export const ReAuthoriseUser = async (email, appId) => {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    let token = `Bearer ${response.accessToken}`

    const headers = new Headers();
    const bearer = token;

    headers.append("CustomAuthorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "email": email,
            "appId": appId
        })
    };

    let apiData = await fetch(`${environment.backendUserService}/api/User/QueryUserByEmail`, options)
    const data = await apiData.json();

    return data;
}

//////////////////////////// Refresh User Token ///////////////////////////////////


export const RefreshUserToken = async (email, refreshToken, appId) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "email": email,
            "refreshToken": refreshToken,
            "appId": appId
        })
    };

    let apiData = await fetch(`${environment.backendUserService}/api/User/RefreshUserToken`, options)
    const data = await apiData.json()

    return data;
}

export async function GetUsers({ organizationId, pageNumber, pageSize }) {
    let fleetToken = await GetToken();
    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "organizationId": organizationId,
            "pageNumber": pageNumber,
            "pageSize": pageSize
        })
    };

    let apiData = await fetch(`${environment.backendUserService}/api/User/QueryUsersList`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function CreateUser(user) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(user)
    };

    let apiData = await fetch(`${environment.backendUserService}/api/User/Create`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}


///////////////////////// New Create User API  /////////////////////////////


export async function CreateAdPreRegisteredUser(user) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(user)
    };

    let apiData = await fetch(`${environment.backendUserService}/api/User/CreateAdPreRegisteredUser`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}


export async function UpdateUser(user) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(user)
    };

    let apiData = await fetch(`${environment.backendUserService}/api/User/Update`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function DeleteUser(email, organizationId) {

    let fleetToken = await GetToken();

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "email": email,
            "organizationId": organizationId
        })
    };

    let apiData = await fetch(`${environment.backendUserService}/api/User/Delete`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

//................. Search functionality .........................//

export async function SearchUser(userEmail, organizationId) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendUserService}/api/User/SearchUsersByName?email=${userEmail}&organizationId=${organizationId}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}