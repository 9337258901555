import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { selectUserGraphData, selectAdUserDetails, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { SetUserGraphData, SetAdUserDetails, SetUserServiceInfo, SetUserPermission } from 'src/redux/user/user.actions';
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Content from 'src/containers/Content/TheContent'
import Welcome from "src/pages/welcome-page/welcome-page"
import bgImage from 'src/assets/img/BackgroundImages/BackgroundImageDark.jpg'
import { callMsGraph, getSignedInUserPhoto_Beta } from "src/services/graph.service";
import { QueryUserByEmail } from 'src/services/user-http.service';
import { getUniversalCookies } from 'src/cookies/cookie';
// import { changeThemeToDark, changeThemeToLight } from 'src/redux/themeActions';
import styled from 'styled-components';
import { paddingLeft } from 'src/containers/LayoutNew/scrollBar/directions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from 'src/redux/sidebarActions';
import { changeContentVisibility, changeMobileContentVisibility } from 'src/redux/mainContentActions';
import Topbar from 'src/containers/LayoutNew/topbar/Topbar';
import { environment } from 'src/environments/environment';
import DefaultPage from 'src/pages/backgrounds/vehicleDetails-default-page';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
});

const Layout = ({ graphData, msalContext, setUserPermission,
  setGraphData, setUserServiceInfo, theme, props }) => {

  const [adUserDetails, SetAdUserDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [togglePage, setTogglePage] = useState(false);
  const settings = useSelector((state) => state.settings);

  const { sidebar, content } = useSelector(state => ({
    sidebar: state.sidebar,
    content: state.content,
    theme: state.theme,
    rtl: state.rtl,
  }));

  const dispatch = useDispatch();

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
    dispatch(changeContentVisibility());

  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
    dispatch(changeMobileContentVisibility());

  };

  // const changeToDark = () => {
  //   dispatch(changeThemeToDark());
  // };

  // const changeToLight = () => {
  //   dispatch(changeThemeToLight());
  // };

  useEffect(() => {
    if (!graphData.displayName) {
      console.log("graphData.displayName -" + graphData.displayName);
      console.log("msalContext.inProgress -" + msalContext.inProgress);
      callMsGraph().then(response => {
        checkoutAccount(response);
        setGraphData(response);
        QueryUserByEmail(response.mail, environment.applicationId, true).then((serviceResponse) => {
          if (serviceResponse.email !== "") {
            serviceResponse.tokenExpiry = Date.now() + serviceResponse.tokenExpiry - 5000
            getUniversalCookies().set('RefreshToken', serviceResponse.refreshToken);
            serviceResponse && serviceResponse.organization ?
              setUserServiceInfo(serviceResponse)
              :
              setUserServiceInfo({
                organization: { id: "" }, parentOrganization: { id: "" }, viewOrganization: { id: "" }, role: {}
              })

            serviceResponse && serviceResponse.permissions ?
              setUserPermission(serviceResponse.permissions)
              :
              setUserPermission([])
          } else {
            setTogglePage(true);
          }

        })//.finally(checkoutAccount(response));
      }
      );
    }
  }, [adUserDetails, theme]);

  ///////////////////////////////// To set loading time 

  const dummyAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 10500));
  }

  const checkoutAccount = (graphData) => {
    SetAdUserDetails({
      ...adUserDetails,
      accountName: graphData.displayName,
      initials: graphData.displayName.charAt(0),
      surname: '',
      givenName: '',
      displayName: graphData.displayName,
      thumbnailPhoto: '',
      email: graphData.mail,
      isAdmin: false,
      graphData: graphData
    });
    setUserPhoto(adUserDetails); // To be used to set user photo after userAD has been set successfully
    dummyAsyncCall().then(() => setLoading(false));
  }

  const setUserPhoto = (userAD) => {
    getSignedInUserPhoto_Beta().then((response) => {
      if (!response.status) {
        if (userAD) {
          userAD.thumbnailPhoto = null;
          SetAdUserDetails(userAD);
        }
      }
      else {
        response.blob.then((data) => {
          const reader = new FileReader()
          reader.readAsDataURL(data)
          reader.onload = () => {
            if (userAD) {
              const base64data = reader.result;
              userAD.thumbnailPhoto = base64data;
              SetAdUserDetails(userAD);
            }
          }
        })
      }
    });
  }


  return (
    <div className="black-background">
      {
        loading ?

          <div id="main-wrapper" >
            <Welcome image={bgImage}></Welcome>
          </div>
          :
          <>
            {
              togglePage ?
                <DefaultPage />
                :
                <div id="main-wrapper"
                  dir={settings.activeDir}
                  data-theme={settings.activeTheme}
                  data-layout={settings.activeThemeLayout}
                  data-sidebartype={settings.activeSidebarType}
                  data-sidebar-position={settings.activeSidebarPos}
                  data-header-position={settings.activeHeaderPos}
                  data-boxed-layout={settings.activeLayout}
                >

                  <LayoutContainer collapse={sidebar.collapse} >

                    <Topbar
                      {...props} userName={graphData.displayName}
                      adUserDetails={adUserDetails}
                      changeMobileSidebarVisibility={mobileSidebarVisibility}
                      changeSidebarVisibility={sidebarVisibility}
                    />

                    {/* <Sidebar {...props} userName={graphData.displayName} routes={SidebarData} adUserDetails={adUserDetails}
                sidebar={sidebar}
                changeToDark={changeToDark}
                changeToLight={changeToLight}
                changeMobileSidebarVisibility={mobileSidebarVisibility}
              /> */}

                  </LayoutContainer>

                  <ContainerWrap collapse={content.collapse} >
                    <Content content={content} />
                  </ContainerWrap>
                </div>
            }
          </>

      }
    </div>
  );
}

const mapStateToProps = state => ({
  graphData: selectUserGraphData(state),
  adUserDetails: selectAdUserDetails(state),
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)

});

const mapDispatchToProps = dispatch => ({
  setGraphData: graphData => dispatch(SetUserGraphData(graphData)),
  SetAdUserDetails: adUserDetails => dispatch(SetAdUserDetails(adUserDetails)),
  setUserServiceInfo: userServiceInfo => dispatch(SetUserServiceInfo(userServiceInfo)),
  setUserPermission: userPermissions => dispatch(SetUserPermission(userPermissions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Layout));

const LayoutContainer = styled.div`
  & + div {
    ${props => props.collapse && `
      ${paddingLeft(props)}: 0;
    `};

    @media screen and (min-width: 576px) {
      ${props => props.collapse && `
        ${paddingLeft(props)}: 60px;
      `}

      ${props => props.topNavigation && `
         ${paddingLeft(props)}: 0;
      `}
    }
  }
`;

const ContainerWrap = styled.div`
  margin-left: ${(props) => (props.collapse ? '200px' : paddingLeft(props))};

`;