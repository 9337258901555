import UserTypes from "src/redux/user/user.types"

const INITIAL_STATE = {
    adUserDetails: {
        isAuthenticated: false,
        surname: '',
        givenName: '',
        email: '',
        isAdmin: false,
        accountName: '',
        initials: '',
        displayName: null,
        thumbnailPhoto: '',
        graphData: {},
    },
    //TODO: Resolve from state and remove hardcoded orgId
    userServiceDetails: {
        organization: {},
        parentOrganization: {},
        viewOrganization: {},
        role: {},
        marketIds: [],
        permissions: []
    }
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserTypes.SET_AD_USER_DETAILS:
            return { ...state, adUserDetails: action.payload }
        case UserTypes.SET_USER_SERVICE_INFO:
            return { ...state, userServiceDetails: action.payload }
        case UserTypes.SET_USER_GRAPH_DATA:
            return { ...state, adUserDetails: { ...state.adUserDetails, graphData: action.payload } }
        case UserTypes.SET_PROFILE_PICTURE:
            return { ...state, adUserDetails: { ...state.adUserDetails, thumbnailPhoto: action.payload } }
        case UserTypes.SET_USER_ROLES:
            return { ...state, userServiceDetails: { ...state.userServiceDetails, roles: action.payload } }
        case UserTypes.SET_USER_SERVICE_PERMISSION:
            return { ...state, userServiceDetails: { ...state.userServiceDetails, permissions: action.payload } }
        default:
            return state
    }
}

export default userReducer;