import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";
import { GetToken } from 'src/auth/userToken';

export async function GetOtganizations() {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendUserService}/api/Organization/Names`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function GetOrganizationById(organizationId) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendUserService}/api/Organization/GetById?id=${organizationId}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}
////////////////
export async function GetOrganizationFamily(userOrganizationId) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendUserService}/api/Organization/OrganizationFamily?parentOrganizationId=${userOrganizationId}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function CreateOrganization(organizationInstance) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(organizationInstance)
    };

    let statusCode = ''
    let apiData = await fetch(`${environment.backendUserService}/api/Organization/Create`, options)
        .then((response) => { statusCode = response.status; return response.json() })
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    apiData = { ...apiData, statusCode: statusCode }

    return apiData;
}

export async function UpdateOrganization(organizationInstance) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(organizationInstance)
    };

    let statusCode = ''
    let apiData = await fetch(`${environment.backendUserService}/api/Organization/Edit`, options)
        .then((response) => { statusCode = response.status; return response.json() })
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    apiData = { ...apiData, statusCode: statusCode }

    return apiData;
}

export async function DeleteOrganization(organizationId) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };
    let apiData = await fetch(`${environment.backendVehicleService}/api/Fleet/CreatedByOrganizationId?organizationId=${organizationId}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));

    console.log(apiData.length);
    if (Array.isArray(apiData) && apiData.length > 0) {
        return {
            Ok: false, errorMessage: `One or more fleets are still connected to the
        organization you wish to remove. Please make sure 
        the selected organization does not contain any fleets.`}
    }

    const optionsDelete = {
        method: "DELETE",
        headers: headers
    };

    let apiDataDelete = await fetch(`${environment.backendUserService}/api/Organization/Delete?id=${organizationId}`, optionsDelete)
        .then((response) => {
            if (response.status === 409) {
                return {
                    Ok: false, errorMessage: `One or more users or children organizations are assigned 
                to the organization you are trying to remove. Please assign 
                these users to a different organization before proceding.`}
            }
            else {
                return { Ok: true }
            }
        }).catch(error => console.log(error));

    return apiDataDelete;
}
////////////////////
export async function GetOrganizationFleets(organizationId) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const headers = new Headers();

    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)


    const options = {
        method: "GET",
        headers: headers
    };
    let apiData = await fetch(`${environment.backendVehicleService}/api/Fleet/CreatedByOrganizationId?organizationId=${organizationId}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));

    console.log(apiData.length);
    if (Array.isArray(apiData) && apiData.length > 0) {
        return {
            Ok: false, errorMessage: `One or more fleets are still connected to the
        organization you wish to remove. Please make sure 
        the selected organization does not contain any fleets.`}
    }

    const optionsDelete = {
        method: "DELETE",
        headers: headers
    };

    let apiDataDelete = await fetch(`${environment.backendUserService}/api/Organization/Delete?id=${organizationId}`, optionsDelete)
        .then((response) => {
            if (response.status === 409) {
                return {
                    Ok: false, errorMessage: `One or more users or children organizations are assigned 
                to the organization you are trying to remove. Please assign 
                these users to a different organization before proceding.`}
            }
            else {
                return { Ok: true }
            }
        }).catch(error => console.log(error));

    return apiDataDelete;
}


//................. Search functionality .........................//

export async function SearchOrganization(organizationName) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendUserService}/api/Organization/SearchOrganizationsByName?name=${organizationName}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

//.............................Pagination functionality........................//

export async function GetOrganizationPaged({ pageNumber, pageSize, parentOrganizationId }) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "parentOrganizationId": parentOrganizationId
        })
    };

    let statusCode = ''
    let apiData = await fetch(`${environment.backendUserService}/api/Organization/QueryOrganizationFamilyPaged`, options)
        .then((response) => { statusCode = response.status; return response.json() })
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    apiData = { ...apiData, statusCode: statusCode }

    return apiData;
}

/*________________________________new Get Organisation API ____________________ */


export async function SearchOrganizationsByApplicationId(applicationID) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendUserService}/api/Organization/SearchOrganizationsByApplicationId?applicationId=${applicationID}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

