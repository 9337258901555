import AppSettings from 'src/AppSettings.json';

export const environment = {
  production: false,
  backendUserService: AppSettings.config["backendUserService"],
  backendVehicleService: AppSettings.config["backendVehicleService"],
  backendMaintenanceService: AppSettings.config["backendMaintenanceService"],
  defaultFleetType: AppSettings.config["defaultFleetType"],
  apimSubscriptionKey: AppSettings.config["apimSubscriptionKey"],
  googleMapsApiKey: AppSettings.config["googleMapsApiKey"],
  clientId: AppSettings.config["clientId"],
  applicationId: AppSettings.config["applicationId"],
  adAuthority: AppSettings.config["adAuthority"],
  backendTodoService: AppSettings.config["backendTodoService"],
  backendProvisioningService: AppSettings.config["backendProvisioningService"],
  backendDashboardService: AppSettings.config["backendDashboardService"],
  backendNotificationService: AppSettings.config["backendNotificationService"]
};