import React, { useState, useEffect, useCallback, useRef } from "react";
import { GoogleMap, InfoWindow, Marker, Autocomplete } from "@react-google-maps/api";
import SearchBar from 'src/components/buttons/search-icon-button.component';
import { selectUserPermissions } from 'src/redux/user/user.selectors';
import InfoWindowComponentMap from "src/components/maps/infoWindowComponentMap.jsx";
import { Geocoding, GetCarCompoundsInMarket, GetVehiclesInCompound, GetCarsInCompoundKpi, GetVehicleCountInCompoundByCompound } from 'src/services/geocoder.service';
import { GetFleets } from 'src/services/fleet-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserParentOrganization } from 'src/redux/user/user.selectors';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import { QueryVehiclePositionByMarket, QueryVehiclePositionForMapView } from 'src/services/vehicle-pages-http.service';
import { styles } from 'src/utils/MapStyle';
import PuffLoader from "react-spinners/PuffLoader";
import { Col, Container, Row } from 'reactstrap';
import FormSelect from 'src/components/form-elements/custom-select.component';
import { makeStyles } from '@material-ui/core/styles';
import CarsAtMCC from 'src/containers/advancedSearchComponents/CarsAtMCC';
import CarsAtLCC from 'src/containers/advancedSearchComponents/CarsAtLCC';
import CarsAtCCC from 'src/containers/advancedSearchComponents/CarsAtCCC';
import MarketCard from 'src/containers/advancedSearchComponents/MarketCard';
import InfoWindowComponentVSP from "./infoWindowComponentVSP";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ChevronLeftIcon from 'src/assets/img/vehicles/ChevronLeftIcon.png';
import ChevronLeftIconGreen from 'src/assets/img/vehicles/ChevronLeftIconGreen.png';
import MCCLCCTable from 'src/components/maps/MCCLCCTable';
import FleetSelectionCard from "src/containers/advancedSearchComponents/FleetSelectionCard";
import { GetFleetTypes } from 'src/services/fleetType-http.service';
import scroll_down from 'src/assets/img/vehicles/scroll_down.png';
import scroll_up from 'src/assets/img/vehicles/scroll_up.png';
import { permission } from 'src/auth/permissions';
import DefaultPage from 'src/pages/backgrounds/default-page';

const useDrawerStyles = makeStyles({
    root2: {
        overflow: 'auto',
        height: '83.5vh',
        paddingRight: '6px',
        marginLeft: '18px',
        marginRight: '-55px',
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            height: '81.3vh',
        },
        '@media (min-width: 1111px) and (max-width: 1275px)': {
            height: '78vh',
            marginLeft: '-8px',
        },
        '@media (min-width: 771px) and (max-width: 850px)': {
            marginLeft: '-15px',
            marginTop: '-3px'
        },
        '@media (min-width: 851px) and (max-width: 1025px)': {
            marginLeft: '-35px',
            marginRight: '-65px',
            marginTop: '-3px'
        },
        '@media (min-width: 1026px) and (max-width: 1075px)': {
            marginLeft: '-35px',
            marginTop: '-3px'
        },
        '@media (min-width: 600px) and (max-width: 770px)': {
            marginLeft: '-30px',
            marginTop: '-3px'
        },
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        },
        overflowX: 'hidden'
    },
    rotateIcon: {
        marginTop: '-100px', fontSize: '20px', letterSpacing: '20px', cursor: 'pointer', marginLeft: '80px', color: '#1ef1c6',
        '@media (min-width: 1001px) and (max-width: 1077px)': {
            marginLeft: '10px',
        },
        '@media (min-width: 600px) and (max-width: 1000px)': {
            marginLeft: '35px',
        },
        animation: "$bounce 2s linear infinite",
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    }
});

const Map = ({ userPermissions, userOrg, userParentOrg, userMarketIds, multiMarketSelect = false, toggleInfoWindowMarker = true }) => {

    const drawerClasses = useDrawerStyles();
    const div = useRef(null);

    const [activeMarker, setActiveMarker] = useState(null);
    const [addressInput, setAddressInput] = useState('')
    const [vehiclePosition, setVeiclePosition] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [markers, setMarkers] = useState([]);
    const [searchOnMapMarkers, setSearchOnMapMarkers] = useState([]);
    const [derivedLatLng, setDerivedLatLng] = useState({ lat: 57.71089814294498, lng: 11.945829642768519 });
    const [mapSpinner, setMapSpinner] = useState(false);
    const [message, setMessage] = useState('');
    const [searchResult, setSearchResult] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [showLCC, setShowLCC] = useState(false);
    const [showMCC, setShowMCC] = useState(false);
    const [showCCC, setShowCCC] = useState(false);
    const [toggleInfoWindow, setToggleInfoWindow] = useState(false);
    const [multiMarketToggleLCC, setMultiMarketToggleLCC] = useState(false);
    const [multiMarketToggleMCC, setMultiMarketToggleMCC] = useState(false);
    const [inputFieldMarker, setInputFieldMarker] = useState(false);
    const [queryParamsMarketIds, setQueryParamsMarketIds] = useState([]);
    const [map, setMap] = useState(null);
    const [disableButtons, setDisablebuttons] = useState(false);
    const [selectedMarkets, setSelectedMarkets] = useState([]);

    const [carsCompoundName, setCarsCompoundName] = useState([]);

    const [vehicleDisplayFlag, setVehicleDisplayFlag] = useState(false);

    const [lccCount, setLCCCount] = useState(null);
    const [mccCount, setMCCCount] = useState(null);
    const [cccCount, setCCCCount] = useState(null);

    const [vehicleCountMCC, setVehicleCountMCC] = useState(null);
    const [vehicleCountLCC, setVehicleCountLCC] = useState(null);
    const [vehicleCountCCC, setVehicleCountCCC] = useState(null);

    const [iconType, setIconType] = useState(false);
    const [showChevronArrow, setShowChevronArrow] = useState(false);

    const [mccType, setMCCType] = useState('');
    const [lccType, setLCCType] = useState('');
    const [cccType, setCCCType] = useState('');

    const [locationType, setLocationType] = useState('');

    const [toggleCountMCC, setToggleCountMCC] = useState(false);
    const [toggleCountLCC, setToggleCountLCC] = useState(false);
    const [toggleCountCCC, setToggleCountCCC] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const imgs = [ChevronLeftIcon, ChevronLeftIconGreen];

    const [icon, setIcon] = useState(imgs[0]);
    const [openIcon, setOpenIcon] = useState(false);

    const [loaderLCC, setLoaderLCC] = useState(false);
    const [loaderMCC, setLoaderMCC] = useState(false);
    const [loaderCCC, setLoaderCCC] = useState(false);

    const [openTable, setOpenTable] = useState(false);

    const [vehiclesAtMCC, setVehiclesAtMCC] = useState([]);

    const [lccmccHeader, setLCCMCCheader] = useState('');
    const [iconDirection, setIconDirection] = useState(false);

    const [fleetTypeIds, setFleetTypeIds] = useState([]);
    const [selectedViewType, setSelectedViewType] = useState([1, 2, 3, 4]);

    const open = Boolean(anchorEl);

    const handleCloseHomeIcon = () => {
        setIcon(imgs[0]);
        setOpenIcon(false);
    };

    const handleOpenHomeIcon = () => {
        setIcon(imgs[1]);
        setOpenIcon(true);
    };

    const handleFleetTypeIds = () => {
        let fleetTypes = []
        GetFleetTypes(userParentOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(fleetType => {
                        fleetTypes.push(fleetType['id'])
                    })
                    setFleetTypeIds(fleetTypes)
                }
            })
    };

    useEffect(() => {
        handleFleetTypeIds();
        if (map) {
            if (markers.length > 1) {
                const bounds = new window.google.maps.LatLngBounds();
                markers?.map(marker => {
                    bounds.extend({ lat: parseFloat(marker.position.lat), lng: parseFloat(marker.position.lng) });
                });
                map.fitBounds(bounds);
            }
            if (markers.length == 0) {
                setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
                map.setOptions({
                    disableDefaultUI: true,
                    zoomControl: true,
                    styles: styles,
                    zoom: 13
                });
            }
            if (markers.length == 1) {
                markers?.map(marker => {
                    setDerivedLatLng({ lat: parseFloat(marker.position.lat), lng: parseFloat(marker.position.lng) });
                });
                map.setOptions({
                    disableDefaultUI: true,
                    zoomControl: true,
                    styles: styles,
                    zoom: 14
                });
            }
            map.setOptions({
                disableDefaultUI: true,
                zoomControl: true,
                styles: styles,
            });
        }
        if (!disableButtons && selectedMarkets.length == 0) {
            setShowLCC(false);
            setShowMCC(false);
            setShowCCC(false);
            setMarkers([]);
            setSelectedViewType([]);
        }
        if (disableButtons && !showLCC && !showMCC && !showCCC) {
            setToggleInfoWindow(true);
            setSelectedViewType([1, 2, 3, 4]);
        }
    }, [map, markers.length, inputFieldMarker, disableButtons, toggleInfoWindow, selectedMarkets.length]);

    const mccImage = {
        url: "../assets/icons/MCCImage.png", // url
        scaledSize: new window.google.maps.Size(80, 80), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
    };

    const mccImageLarge = {
        url: "../assets/icons/MCCImage.png", // url
        scaledSize: new window.google.maps.Size(100, 100), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
    };

    const lccImage = {
        url: "../assets/icons/LCCImage.png", // url
        scaledSize: new window.google.maps.Size(110, 110), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
    };

    const lccImageLarge = {
        url: "../assets/icons/LCCImage.png", // url
        scaledSize: new window.google.maps.Size(130, 130), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
    };

    const cccImage = {
        url: "../assets/icons/CCCImage.png", // url
        scaledSize: new window.google.maps.Size(80, 80), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
    };

    const cccImageLarge = {
        url: "../assets/icons/CCCImage.png", // url
        scaledSize: new window.google.maps.Size(100, 100), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
    };

    const MapPin = {
        url: "../assets/icons/MapPin.png", // url
        scaledSize: new window.google.maps.Size(40, 40), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
    };

    const handleActiveMarker = (marker) => {
        let fleetIds = []
        let marketIds = userMarketIds;
        let vin = marker
        let vehiclePositionInstance = {
            "vin": "",
            "latitude": 0,
            "longitude": 0,
            "vehicleUpdatedAt": "",
            "color": "",
            "odometer": 0,
            "street": "",
            "postalCode": "",
            "city": "",
            "country": "",
        }
        GetFleets(userParentOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })

                    QueryVehiclePositionForMapView(vin, fleetIds, marketIds)
                        .then(data => {
                            if (data.ok == true) {
                                vehiclePositionInstance.vin = data.vehiclePosition.vin
                                vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                vehiclePositionInstance.color = data.vehiclePosition.color
                                vehiclePositionInstance.odometer = data.vehiclePosition.odometer
                                vehiclePositionInstance.street = data.vehiclePosition.addressData.street
                                vehiclePositionInstance.postalCode = data.vehiclePosition.addressData.postalCode
                                vehiclePositionInstance.city = data.vehiclePosition.addressData.city
                                vehiclePositionInstance.country = data.vehiclePosition.addressData.country
                                setVeiclePosition(vehiclePositionInstance);
                            }
                        })
                }
            })
        setActiveMarker(marker);
    };

    const handleActiveMarkerMCCLCC = (marker, name, type) => {
        setCarsCompoundName(name);
        setActiveMarker(marker);
        setIconType(true);
        setLocationType(type);
        let viewTypes = [1, 2, 3, 4];

        if (type == 'LCC') {
            setLoaderLCC(true);
            GetVehicleCountInCompoundByCompound(selectedMarkets[0], name, viewTypes, fleetTypeIds).then(data => {
                if (data.ok == true) {
                    setToggleCountLCC(true);
                    setVehicleCountLCC(data.vehicleCount);
                }
                setLoaderLCC(false);
            });
        }

        if (type == 'MCC') {
            setLoaderMCC(true);
            GetVehicleCountInCompoundByCompound(selectedMarkets[0], name, viewTypes, fleetTypeIds).then(data => {
                if (data.ok == true) {
                    setToggleCountMCC(true);
                    setVehicleCountMCC(data.vehicleCount);
                }
                setLoaderMCC(false);
            });
        }

        if (type == 'CCC') {
            setLoaderCCC(true);
            GetVehicleCountInCompoundByCompound(selectedMarkets[0], name, viewTypes, fleetTypeIds).then(data => {
                if (data.ok == true) {
                    setToggleCountCCC(true);
                    setVehicleCountCCC(data.vehicleCount);
                }
                setLoaderCCC(false);
            });
        }
    };

    const onLoad = useCallback((map) => setMap(map), []);

    const handleOnLoad = (map) => {
        // const bounds = new window.google.maps.LatLngBounds();
        // markers.forEach(({ position }) => bounds.extend(position));
        // map.fitBounds(bounds);
        map.setOptions({
            disableDefaultUI: true,
            zoomControl: true,
            styles: styles,
        });
    };

    const handleSearch = (address) => {
        let viewTypes = [1, 2, 3, 4];
        setAddressInput(address);

        if (addressInput.trim()?.length != 0) {
            setMapSpinner(true);
            let marketId = userMarketIds;
            Geocoding({ address }).then(data => {
                if (data.status == 'ok') {
                    setMapSpinner(false);
                    if (data.message && Array.isArray(data.message)) {

                        const retrieveCountry = data.message[0].address_components.find(address => address['types'].includes('country'))['short_name']
                        const retrieveLatLog = data.message[0].geometry['location']

                        setDerivedLatLng(retrieveLatLog);
                        const matchesMarket = marketId.find(element => {
                            if (element.includes(retrieveCountry)) {
                                return true;
                            }
                        });

                        if (matchesMarket !== undefined) {
                            let fleetIds = []
                            let vehiclePositionArray = [];
                            let marketIds = userMarketIds;
                            let userMarketId = [...selectedMarkets, matchesMarket]
                            GetFleets(userParentOrg.id, userMarketIds)
                                .then(data => {
                                    if (data && Array.isArray(data)) {
                                        data.map(item => {
                                            fleetIds.push(item.id);
                                        })

                                        QueryVehiclePositionByMarket(fleetIds, marketIds, userMarketId, viewTypes, fleetTypeIds)
                                            .then(data => {
                                                if (data.ok == true) {

                                                    data.vehiclePositions.map(vehicle => {
                                                        vehiclePositionArray.push({
                                                            vin: vehicle.vin,
                                                            position: { lat: vehicle.latitude, lng: vehicle.longitude },


                                                        });
                                                    });

                                                }
                                                setSearchOnMapMarkers(vehiclePositionArray)
                                            })
                                    }
                                })
                        } else {
                            setDialogMessage('Selected location is not part of your market. Please try again');
                            setOpenDialog(true);
                            setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
                            setSearchOnMapMarkers([]);
                        }
                    }
                } else {
                    setMapSpinner(false);
                    setDialogMessage('Invalid location, please try again')
                    setOpenDialog(true);
                    setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
                }
            })
        }
    };

    const handleSearchToggle = (updatedSelectedmarket) => {
        let viewTypes = [0];

        if (updatedSelectedmarket.length > 0) {
            setMarkers([]);
            let fleetIds = []
            let vehiclePositionArray = [];
            let marketIds = userMarketIds;
            setMapSpinner(true);
            GetFleets(userParentOrg.id, userMarketIds)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })
                        QueryVehiclePositionByMarket(fleetIds, marketIds, updatedSelectedmarket, viewTypes, fleetTypeIds)
                            .then(data => {
                                if (data.ok == true) {
                                    data.vehiclePositions.map(vehicle => {
                                        vehiclePositionArray.push({
                                            vin: vehicle.vin,
                                            position: { lat: vehicle.latitude, lng: vehicle.longitude }
                                        });
                                    });
                                }
                                setMarkers(vehiclePositionArray);
                                setMapSpinner(false);
                            })
                    }
                })
        } else {
            setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
            setMarkers([]);
        }
    };

    const handleGetLCCMCCData = (markets, type) => {
        if (markets.length > 0) {
            setMarkers([]);
            let vehiclePositionArray = [];
            setMapSpinner(true);

            GetCarCompoundsInMarket(markets, type)
                .then(data => {
                    if (data.ok == true) {
                        data.carCompounds.map(vehicle => {
                            let firstpartVehicle = vehicle?.postalCode === null ? '' : vehicle?.postalCode;
                            let secondpartVehicle = vehicle?.city === null ? '' : vehicle?.city;
                            const postalCodeValueVehicle = firstpartVehicle + ' ' + secondpartVehicle
                            vehiclePositionArray.push({
                                icon: vehicle.locationType,
                                type: vehicle.locationType,
                                street: vehicle.street,
                                postalCode: postalCodeValueVehicle,
                                country: vehicle.country,
                                heading: vehicle.name,
                                position: { lat: parseFloat(vehicle.latitude), lng: parseFloat(vehicle.longitude) }
                            });
                        });
                    }
                    setMarkers(vehiclePositionArray);
                    setMapSpinner(false);
                })
        } else {
            setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
            setMarkers([]);
        }
    };

    const handleSearchInput = (event) => {
        setAddressInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
            setSearchOnMapMarkers([]);
        };
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
    };

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const onPlaceChanged = () => {
        if (searchResult != null) {
            const address = searchResult.getPlace().formatted_address;
            handleSearch(address);
        }
    };

    const onLoadAddress = (autocomplete) => {
        setSearchResult(autocomplete);
    };

    const haandleDropdown = (e) => {
        const { name, value } = e.target;
        if (value == 1) {
            setIsExpanded(!isExpanded);
            setMarkers([]);
            setSearchOnMapMarkers([]);
            setSelectedMarkets([]);
            setShowLCC(false);
            setShowMCC(false);
            setShowCCC(false);
            setVehicleDisplayFlag(false);
            setMCCType('');
            setLCCType('');
            setCCCType('');
            setActiveMarker(null);
            setIconType(false);
            setVehicleCountLCC(null);
            setVehicleCountMCC(null);
            setVehicleCountCCC(null);
            setToggleCountLCC(false);
            setToggleCountMCC(false);
            setToggleCountCCC(false);
        }
    };

    const handlePositionMCC = (type) => {
        let updatedData = [];
        let typeArray = [];
        if (type == 'MCC' && !showLCC && !showCCC) {
            typeArray = [type]
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountMCC(selectedMarkets);
        }

        if (type == 'MCC' && !showLCC && showCCC) {
            typeArray = [type, 'CCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountMCC(selectedMarkets);
        }

        if (type == 'false' && !showLCC && showCCC) {
            typeArray = ['CCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountMCC(selectedMarkets);
        }

        if (type == 'false' && !showLCC && !showCCC) {
            handleSearchToggle(selectedMarkets);
            setToggleInfoWindow(true);
            setActiveMarker(null);
            setShowChevronArrow(false);
        }

        if (type == 'MCC' && showLCC && !showCCC) {
            typeArray = [type, 'LCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountMCC(selectedMarkets);
        }

        if (type == 'MCC' && showLCC && !showCCC) {
            typeArray = [type, 'LCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountMCC(selectedMarkets);
        }

        if (type == 'false' && showLCC && !showCCC) {
            typeArray = ['LCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountMCC(selectedMarkets);
        }

        if (type == 'MCC' && showLCC && showCCC) {
            typeArray = [type, 'LCC', 'CCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountMCC(selectedMarkets);
        }

        if (type == 'false' && showCCC && showLCC) {
            typeArray = ['CCC', 'LCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountMCC(selectedMarkets);
        }
        setMarkers(updatedData);
    };

    const handlePositionLCC = (type) => {
        let updatedData = [];
        let typeArray = [];
        if (type == 'LCC' && !showLCC && !showCCC) {
            typeArray = [type]
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountLCC(selectedMarkets);
        }

        if (type == 'LCC' && showMCC && !showCCC) {
            typeArray = [type, 'MCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountLCC(selectedMarkets);
        }

        if (type == 'false' && showMCC && !showCCC) {
            typeArray = ['MCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountLCC(selectedMarkets);
        }

        if (type == 'false' && !showMCC && !showCCC) {
            handleSearchToggle(selectedMarkets);
            setToggleInfoWindow(true);
            setActiveMarker(null);
            setShowChevronArrow(false);
        }

        if (type == 'LCC' && !showMCC && showCCC) {
            typeArray = [type, 'CCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountLCC(selectedMarkets);
        }

        if (type == 'false' && !showMCC && showCCC) {
            typeArray = ['CCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountLCC(selectedMarkets);
        }

        if (type == 'LCC' && showCCC && showMCC) {
            typeArray = [type, 'CCC', 'MCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountLCC(selectedMarkets);
        }
        if (type == 'false' && showCCC && showMCC) {
            typeArray = ['CCC', 'MCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountLCC(selectedMarkets);
        }
        setMarkers(updatedData);
    };

    const handlePositionCCC = (type) => {
        let updatedData = [];
        let typeArray = [];
        if (type == 'CCC' && !showLCC && !showCCC) {
            typeArray = [type]
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountCCC(selectedMarkets);
        }

        if (type == 'CCC' && !showLCC && showMCC) {
            typeArray = [type, 'MCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountCCC(selectedMarkets);
        }

        if (type == 'false' && !showLCC && showMCC) {
            typeArray = ['MCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountCCC(selectedMarkets);
        }

        if (type == 'false' && !showLCC && !showMCC) {
            handleSearchToggle(selectedMarkets);
            setToggleInfoWindow(true);
            setActiveMarker(null);
            setShowChevronArrow(false);
        }

        if (type == 'CCC' && showLCC && !showMCC) {
            typeArray = [type, 'LCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountCCC(selectedMarkets);
        }

        if (type == 'false' && showLCC && !showMCC) {
            typeArray = ['LCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountCCC(selectedMarkets);
        }

        if (type == 'CCC' && showLCC && showMCC) {
            typeArray = [type, 'LCC', 'MCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountCCC(selectedMarkets);
        }

        if (type == 'false' && showLCC && showMCC) {
            typeArray = ['LCC', 'MCC']
            handleGetLCCMCCData(selectedMarkets, typeArray);
            updatedData = [...markers];
            setToggleInfoWindow(false);
            handleVehicleCountCCC(selectedMarkets);
        }
        setMarkers(updatedData);
    };

    const handleChange = () => {
        setToggleInfoWindow(true);
        let viewTypes = [1, 2, 3, 4];
        if (carsCompoundName !== undefined) {
            setShowChevronArrow(true);
            setVehicleDisplayFlag(true);
            let vehiclePositionArray = [];
            setMapSpinner(true);

            if (locationType == 'LCC') {
                setLoaderLCC(true);
                GetVehicleCountInCompoundByCompound(selectedMarkets[0], carsCompoundName, viewTypes, fleetTypeIds).then(data => {
                    if (data.ok == true) {
                        setToggleCountLCC(true);
                        setVehicleCountLCC(data.vehicleCount);
                        setActiveMarker(activeMarker);
                        setLoaderLCC(false);
                    }
                });
            }

            if (locationType == 'MCC') {
                setLoaderMCC(true);
                GetVehicleCountInCompoundByCompound(selectedMarkets[0], carsCompoundName, viewTypes, fleetTypeIds).then(data => {
                    if (data.ok == true) {
                        setToggleCountMCC(true);
                        setVehicleCountMCC(data.vehicleCount);
                        setActiveMarker(activeMarker);
                        setLoaderMCC(false);
                    }
                });
            }

            if (locationType == 'CCC') {
                setLoaderCCC(true);
                GetVehicleCountInCompoundByCompound(selectedMarkets[0], carsCompoundName, viewTypes, fleetTypeIds).then(data => {
                    if (data.ok == true) {
                        setToggleCountCCC(true);
                        setVehicleCountCCC(data.vehicleCount);
                        setActiveMarker(activeMarker);
                        setLoaderCCC(false);
                    }
                });
            }

            GetVehiclesInCompound(selectedMarkets[0], carsCompoundName, viewTypes, fleetTypeIds)
                .then(data => {
                    if (data.ok == true) {
                        data.vehiclesPostion.map(vehicle => {
                            vehiclePositionArray.push({
                                type: 'MCC',
                                vin: vehicle.vin,
                                position: { lat: vehicle.latitude, lng: vehicle.longitude },
                            });
                        });
                    }
                    setMarkers(vehiclePositionArray);
                    setMapSpinner(false);
                })
        } else {
            setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
            setMarkers([]);
        }
    };

    const handleVehicleCountMCC = (markets) => {
        let viewTypes = [1, 2, 3, 4];
        setLoaderMCC(true);
        GetCarsInCompoundKpi(markets, viewTypes, fleetTypeIds).then(data => {

            if (data.ok == true) {
                setMCCCount(data.carsInMCC);
            }
            setLoaderMCC(false)
        })
    };

    const handleVehicleCountLCC = (markets) => {
        let viewTypes = [1, 2, 3, 4];
        setLoaderLCC(true);
        GetCarsInCompoundKpi(markets, viewTypes, fleetTypeIds).then(data => {

            if (data.ok == true) {
                setLCCCount(data.carsInLCC);
            }
            setLoaderLCC(false);
        })
    };

    const handleVehicleCountCCC = (markets) => {
        let viewTypes = [1, 2, 3, 4];
        setLoaderCCC(true);
        setCCCCount(20);
        GetCarsInCompoundKpi(markets, viewTypes, fleetTypeIds).then(data => {

            if (data.ok == true) {
                setCCCCount(data.carsInCCC);
            }
            setLoaderCCC(false)
        })
    };

    const handleToggleChevron = () => {
        setOpenTable(false);
        let typeArray = [];

        if (mccType && !showLCC) {
            typeArray = [mccType]
            handleGetLCCMCCData(selectedMarkets, typeArray);
            setToggleInfoWindow(false);
            setShowChevronArrow(false);
            setActiveMarker(null);
            setIcon(imgs[0]);
            handleVehicleCountMCC(selectedMarkets);
            setVehicleCountMCC(null);
            setToggleCountMCC(false);
        }

        if (mccType && showLCC) {
            typeArray = [mccType, 'LCC'];
            handleGetLCCMCCData(selectedMarkets, typeArray);
            setToggleInfoWindow(false);
            setShowChevronArrow(false);
            setActiveMarker(null);
            setIcon(imgs[0]);
            handleVehicleCountMCC(selectedMarkets);
            handleVehicleCountLCC(selectedMarkets);
            setVehicleCountLCC(null);
            setToggleCountLCC(false);
            setVehicleCountMCC(null);
            setToggleCountMCC(false);
        }

        if (lccType && !showMCC) {
            typeArray = [lccType]
            handleGetLCCMCCData(selectedMarkets, typeArray);
            setToggleInfoWindow(false);
            setShowChevronArrow(false);
            setActiveMarker(null);
            setIcon(imgs[0]);
            handleVehicleCountLCC(selectedMarkets);
            setVehicleCountLCC(null);
            setToggleCountLCC(false);
        }

        if (lccType && showMCC) {
            typeArray = ['MCC', 'LCC'];
            handleGetLCCMCCData(selectedMarkets, typeArray);
            setToggleInfoWindow(false);
            setShowChevronArrow(false);
            setActiveMarker(null);
            setIcon(imgs[0]);
            handleVehicleCountLCC(selectedMarkets);
            handleVehicleCountMCC(selectedMarkets);
            setVehicleCountLCC(null);
            setToggleCountLCC(false);
            setVehicleCountMCC(null);
            setToggleCountMCC(false);
        }
    };

    const handleMCCLCCTable = () => {
        let viewTypes = [1, 2, 3, 4];
        setOpenTable(true);
        let vehicleVINArray = [];
        if (carsCompoundName !== undefined) {
            GetVehiclesInCompound(selectedMarkets[0], carsCompoundName, viewTypes, fleetTypeIds)
                .then(data => {
                    if (data.ok == true) {
                        data.vehiclesPostion.map(vehicle => {
                            vehicleVINArray.push(
                                vehicle['vin']
                            );
                        });
                    }
                    setVehiclesAtMCC(vehicleVINArray);
                })
        }
    };

    const handleCloseMCCLCCTable = () => {
        setOpenTable(false);
        setVehiclesAtMCC([]);
    };

    const matchesPermission = userPermissions.includes(permission.Location_Reader);

    return (
        <Container>
            <Col md={12} lg={12}>
                <VehiclePageDialog
                    dialogue='true'
                    open={openDialog}
                    message={dialogMessage}
                    handleDialogOk={handleDialogOk}
                    title={message}
                />
                <>
                    {
                        matchesPermission ?
                            isExpanded ?
                                <Row>
                                    <Col md={9} sm={9} xs={9} lg={10}>

                                        <Row style={{ margin: '15px -10px -15px 0', display: 'block', flexWrap: 'wrap' }}>
                                            {
                                                mapSpinner ?

                                                    <div style={{ position: 'absolute', width: '99.1%', height: '83.6vh', marginRight: '-35px', alignItems: 'center', backgroundColor: '#212121', marginTop: '132px', borderRadius: '15px', boxShadow: '3px 5px 6px #000000', display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                                            <PuffLoader color="#1EF1C6" size={200} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            openTable ?
                                                                <div style={{ marginTop: '150px' }}>
                                                                    <MCCLCCTable
                                                                        handleCloseMCCLCCTable={handleCloseMCCLCCTable}
                                                                        vehiclesAtMCC={vehiclesAtMCC}
                                                                        lccmccHeader={lccmccHeader}
                                                                        loaderMCC={loaderMCC}
                                                                        locationType={locationType}
                                                                    />
                                                                </div>
                                                                :
                                                                <GoogleMap
                                                                    onLoad={onLoad}
                                                                    onClick={() => setActiveMarker(null)}
                                                                    mapContainerClassName='mapHeightAdvance'
                                                                    center={derivedLatLng}
                                                                    zoom={13}
                                                                >
                                                                    <Col lg="12" md="12" >
                                                                        <Row style={{ margin: '15px -10px -15px 0', display: 'block', flexWrap: 'wrap' }}>
                                                                            <span>
                                                                                {
                                                                                    isExpanded ?
                                                                                        <>
                                                                                            {
                                                                                                showChevronArrow && isExpanded ?
                                                                                                    <span style={{ fontSize: '18px', float: 'left', color: 'darkgray', marginTop: '10px' }}>
                                                                                                        <Tooltip
                                                                                                            title="Return"
                                                                                                            onOpen={handleOpenHomeIcon}
                                                                                                            onClose={handleCloseHomeIcon}
                                                                                                            open={openIcon}
                                                                                                        >
                                                                                                            <IconButton
                                                                                                                onClick={handleToggleChevron}
                                                                                                                size="small"
                                                                                                                sx={{ ml: 2 }}
                                                                                                                aria-controls={open ? "account-menu" : undefined}
                                                                                                                aria-haspopup="true"
                                                                                                                aria-expanded={open ? "true" : undefined}
                                                                                                            >
                                                                                                                <img
                                                                                                                    className="dot"
                                                                                                                    src={icon}
                                                                                                                    alt="Three Dot Image"
                                                                                                                />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </span>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <span style={{ fontSize: '18px', float: 'left', color: 'darkgray' }}>
                                                                                            <div style={{ width: '500%', marginTop: '20px', marginLeft: '10px' }}>
                                                                                                <Autocomplete
                                                                                                    onPlaceChanged={onPlaceChanged}
                                                                                                    onLoad={onLoadAddress}
                                                                                                >
                                                                                                    <SearchBar
                                                                                                        handleSearch={handleSearch}
                                                                                                        handleSearchInput={handleSearchInput}
                                                                                                        onKeyPress={textFieldKeypress}
                                                                                                        placeholder={'Search on map'}
                                                                                                        value={addressInput}
                                                                                                        autoFocus="true"
                                                                                                    />
                                                                                                </Autocomplete>
                                                                                            </div>
                                                                                        </span>
                                                                                }
                                                                            </span>

                                                                            <span className="optionsSelectMap">
                                                                                {
                                                                                    <FormSelect
                                                                                        menuObjectArray={[
                                                                                            { 'key': '1', 'value': 'Close Advanced Search' }
                                                                                        ]}
                                                                                        onClick={haandleDropdown}
                                                                                    />
                                                                                }
                                                                            </span>
                                                                        </Row>
                                                                    </Col>

                                                                    {
                                                                        toggleInfoWindow ?
                                                                            <>
                                                                                {markers.map(({ vin, position }) => (
                                                                                    <Marker
                                                                                        key={vin}
                                                                                        position={position}
                                                                                        onClick={() => handleActiveMarker(vin)}
                                                                                        icon={MapPin}
                                                                                    >
                                                                                        {activeMarker === vin ? (
                                                                                            <InfoWindow
                                                                                                onCloseClick={() => setActiveMarker(null)}
                                                                                            >
                                                                                                <div >
                                                                                                    <InfoWindowComponentMap

                                                                                                        vehiclePosition={vehiclePosition}
                                                                                                    />
                                                                                                </div>
                                                                                            </InfoWindow>
                                                                                        ) : null}
                                                                                    </Marker>
                                                                                ))}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {markers.map((value, index) => (
                                                                                    <Marker
                                                                                        key={index}
                                                                                        position={value.position}
                                                                                        onClick={() => handleActiveMarkerMCCLCC(index, value.heading, value.type)}
                                                                                        icon={value.icon == 'LCC' ? (iconType && activeMarker === index ? lccImageLarge : lccImage) : value.icon == 'CCC' ? (iconType && activeMarker === index ? cccImageLarge : cccImage) : (iconType && activeMarker === index ? mccImageLarge : mccImage)}
                                                                                        zIndex={value.icon == 'LCC' ? 10 : 1}
                                                                                    >
                                                                                        {activeMarker === index ? (
                                                                                            <InfoWindow
                                                                                                onCloseClick={() => {
                                                                                                    setIconType(false);
                                                                                                    setActiveMarker(null);
                                                                                                    setVehicleCountLCC(null);
                                                                                                    setVehicleCountMCC(null);
                                                                                                    setToggleCountLCC(false);
                                                                                                    setToggleCountMCC(false);
                                                                                                    setToggleCountCCC(false);
                                                                                                }}
                                                                                                onUnmount={() => {
                                                                                                    setIconType(false);
                                                                                                    setActiveMarker(null);
                                                                                                    setVehicleCountLCC(null);
                                                                                                    setVehicleCountMCC(null);
                                                                                                    setVehicleCountCCC(null);
                                                                                                    setToggleCountLCC(false);
                                                                                                    setToggleCountMCC(false);
                                                                                                    setToggleCountCCC(false);
                                                                                                }}
                                                                                            >
                                                                                                <InfoWindowComponentVSP
                                                                                                    heading={value.heading}
                                                                                                    street={value.street}
                                                                                                    postalCode={value.postalCode}
                                                                                                    country={value.country}
                                                                                                    vehicleUpdatedAt={value.vehicleUpdatedAt}
                                                                                                    handleChange={handleChange}
                                                                                                    handleMCCLCCTable={handleMCCLCCTable}
                                                                                                    toggleInfoWindowMarker={toggleInfoWindowMarker}
                                                                                                    vehicleCountLCC={vehicleCountLCC}
                                                                                                    loaderLCC={loaderLCC}
                                                                                                    loaderMCC={loaderMCC}
                                                                                                    loaderCCC={loaderCCC}
                                                                                                    vehicleCountMCC={vehicleCountMCC}
                                                                                                    vehicleCountCCC={vehicleCountCCC}
                                                                                                    setLCCMCCheader={setLCCMCCheader}
                                                                                                />
                                                                                            </InfoWindow>
                                                                                        ) : null}
                                                                                    </Marker>
                                                                                )
                                                                                )}
                                                                            </>
                                                                    }
                                                                </GoogleMap>
                                                        }
                                                    </>
                                            }
                                        </Row>
                                    </Col>
                                    <Col md={3} sm={3} xs={3} lg={2} style={{ marginTop: '150px' }}>
                                        <div className={drawerClasses.root2} >
                                            <div ref={div}>
                                                <div>
                                                    <MarketCard
                                                        setQueryParamsMarketIds={setQueryParamsMarketIds}
                                                        multiMarketSelect={multiMarketSelect}
                                                        setMarkers={setMarkers}
                                                        setMultiMarketToggleMCC={setMultiMarketToggleMCC}
                                                        setMultiMarketToggleLCC={setMultiMarketToggleLCC}
                                                        multiMarketToggleLCC={multiMarketToggleLCC}
                                                        multiMarketToggleMCC={multiMarketToggleMCC}
                                                        setToggleInfoWindow={setToggleInfoWindow}
                                                        setInputFieldMarker={setInputFieldMarker}
                                                        inputFieldMarker={inputFieldMarker}
                                                        setDisablebuttons={setDisablebuttons}
                                                        setActiveMarker={setActiveMarker}
                                                        setSelectedMarkets={setSelectedMarkets}
                                                        selectedMarkets={selectedMarkets}
                                                        handleSearchToggle={handleSearchToggle}
                                                        handleGetLCCMCCData={handleGetLCCMCCData}
                                                        showLCC={showLCC}
                                                        showMCC={showMCC}
                                                        showCCC={showCCC}
                                                        handleVehicleCountLCC={handleVehicleCountLCC}
                                                        handleVehicleCountMCC={handleVehicleCountMCC}
                                                        handleVehicleCountCCC={handleVehicleCountCCC}
                                                        setShowChevronArrow={setShowChevronArrow}
                                                        handleCloseMCCLCCTable={handleCloseMCCLCCTable}
                                                        isStandardMarket="true"
                                                    />
                                                </div>
                                                <div style={{ marginTop: '20px' }}>
                                                    <CarsAtLCC
                                                        handlePosition={handlePositionLCC}
                                                        setShowLCC={setShowLCC}
                                                        disableButtons={disableButtons}
                                                        setVehicleDisplayFlag={setVehicleDisplayFlag}
                                                        lccCount={lccCount}
                                                        setLCCType={setLCCType}
                                                        activeMarker={activeMarker}
                                                        vehicleCountLCC={vehicleCountLCC}
                                                        toggleCountLCC={toggleCountLCC}
                                                        loader={loaderLCC}
                                                        handleCloseMCCLCCTable={handleCloseMCCLCCTable}
                                                    />
                                                </div>

                                                <div style={{ marginTop: '-20px' }}>
                                                    <CarsAtMCC
                                                        handlePosition={handlePositionMCC}
                                                        setShowMCC={setShowMCC}
                                                        disableButtons={disableButtons}
                                                        setVehicleDisplayFlag={setVehicleDisplayFlag}
                                                        mccCount={mccCount}
                                                        setMCCType={setMCCType}
                                                        vehicleCountMCC={vehicleCountMCC}
                                                        activeMarker={activeMarker}
                                                        toggleCountMCC={toggleCountMCC}
                                                        loader={loaderMCC}
                                                        handleCloseMCCLCCTable={handleCloseMCCLCCTable}
                                                    />
                                                </div>

                                                <div style={{ marginTop: '-20px' }}>
                                                    <CarsAtCCC
                                                        handlePosition={handlePositionCCC}
                                                        setShowCCC={setShowCCC}
                                                        disableButtons={disableButtons}
                                                        setVehicleDisplayFlag={setVehicleDisplayFlag}
                                                        cccCount={cccCount}
                                                        setCCCType={setCCCType}
                                                        vehicleCountCCC={vehicleCountCCC}
                                                        activeMarker={activeMarker}
                                                        toggleCountCCC={toggleCountCCC}
                                                        setToggleCountCCC={setToggleCountCCC}
                                                        loader={loaderCCC}
                                                        handleCloseMCCLCCTable={handleCloseMCCLCCTable}
                                                    />
                                                </div>
                                                <div style={{ marginTop: '-20px' }}>
                                                    <FleetSelectionCard
                                                        openTable={openTable}
                                                        selectedMarkets={selectedMarkets[0]}
                                                        carsCompoundName={carsCompoundName}
                                                        fleetTypeIds={fleetTypeIds}
                                                        setVehiclesAtMCC={setVehiclesAtMCC}
                                                        disableButtons={disableButtons}
                                                        setSelectedViewType={setSelectedViewType}
                                                        selectedViewType={selectedViewType}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}  >
                                            <Tooltip title={iconDirection ? "Scroll Up" : "Scroll Down"}
                                                className={drawerClasses.rotateIcon}
                                                onClick={() => {
                                                    iconDirection ?
                                                        div.current?.scrollIntoView({ behavior: "smooth", alignToTop: "true" }) : div.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: 'nearest' })
                                                    setIconDirection(!iconDirection)
                                                }}
                                            >
                                                {
                                                    iconDirection ?
                                                        <span>
                                                            <img
                                                                className="dot"
                                                                src={scroll_up}
                                                                alt="Three Dot Image"
                                                            />
                                                        </span>
                                                        :
                                                        <span>
                                                            <img
                                                                className="dot"
                                                                src={scroll_down}
                                                                alt="Three Dot Image"
                                                            />
                                                        </span>
                                                }
                                            </Tooltip>
                                        </Row>
                                    </Col>
                                </Row>
                                :
                                <>
                                    {
                                        mapSpinner ?
                                            <div style={{ position: 'absolute', width: '100.8%', height: '83.6vh', alignItems: 'center', backgroundColor: '#212121', marginTop: '150px', borderRadius: '15px', boxShadow: '3px 5px 6px #000000', display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                                    <PuffLoader color="#1EF1C6" size={200} />
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {
                                                    <GoogleMap
                                                        onLoad={handleOnLoad}
                                                        onClick={() => setActiveMarker(null)}
                                                        mapContainerClassName='mapHeight'
                                                        center={derivedLatLng}
                                                        zoom={13}
                                                    >
                                                        <Col lg="12" md="12" >
                                                            <Row style={{ margin: '15px -10px -15px 0', display: 'block', flexWrap: 'wrap' }}>
                                                                <div style={{ marginTop: '18px', marginLeft: '-10px', marginBottom: '-68px' }}>
                                                                    <Autocomplete
                                                                        onPlaceChanged={onPlaceChanged}
                                                                        onLoad={onLoadAddress}
                                                                    >
                                                                        <SearchBar
                                                                            handleSearch={handleSearch}
                                                                            handleSearchInput={handleSearchInput}
                                                                            onKeyPress={textFieldKeypress}
                                                                            placeholder={'Search on map'}
                                                                            value={addressInput}
                                                                            autoFocus="true"
                                                                        />
                                                                    </Autocomplete>
                                                                </div>
                                                                {
                                                                    <span className="optionsSelectMap">
                                                                        {
                                                                            <FormSelect
                                                                                menuObjectArray={[
                                                                                    { 'key': '1', 'value': 'Open Advanced Search' }
                                                                                ]}
                                                                                onClick={haandleDropdown}
                                                                            />
                                                                        }
                                                                    </span>
                                                                }
                                                            </Row>
                                                        </Col>
                                                        {searchOnMapMarkers.map(({ vin, position }) => (
                                                            <Marker
                                                                key={vin}
                                                                position={position}
                                                                onClick={() => handleActiveMarker(vin)}
                                                                icon={MapPin}
                                                            >
                                                                {activeMarker === vin ? (
                                                                    <InfoWindow
                                                                        onCloseClick={() => setActiveMarker(null)}
                                                                    >
                                                                        <div >
                                                                            <InfoWindowComponentMap vehiclePosition={vehiclePosition} />
                                                                        </div>
                                                                    </InfoWindow>
                                                                ) : null}
                                                            </Marker>
                                                        ))}
                                                    </GoogleMap>
                                                }
                                            </>
                                    }
                                </>
                            :
                            <DefaultPage />
                    }
                </>
            </Col>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state),
    userParentOrg: selectUserParentOrganization(state)
});

export default connect(mapStateToProps, null)(Map);