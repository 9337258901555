import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import ReactTableBase from 'src/components/tables/newtable/table/ReactTableBase';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormmatDate } from 'src/utils/DateFormatter';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import EnhancedTableToolbar from 'src/components/tables/table-toolbar.component';
import AlertDialog from 'src/components/form-elements/dialog-window.component';
import Drawer from '@material-ui/core/Drawer';
import FleetAddEdit from 'src/components/form-elements/fleet-add-edit.component';
import { GetFleets, CreateFleet, UpdateFleet, DeleteFleet, SearchFleet, GetFleetPaged } from 'src/services/fleet-http.service';
import { selectUserOrganization, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { permission } from 'src/auth/permissions';
import Fade from 'react-reveal/Fade';
import SearchField from 'src/components/buttons/search-icon-button-new.component';
import magnifyicon_hover from 'src/assets/img/vehicles/magnifyicon_hover.png';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { GetOrganizationById } from "src/services/organization-http.service";

let rowsPerPage = 200;
let newPageVariable = 1;
const drawerWidth = 240;

const useDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: '45px'
    },
    paper: {
        '&:: -webkit-scrollbar': {
            width: 'Zpx',
            height: '1px',
            backgroundColor: '#aaa',
            background: '#000',
        },
        width: '100%',
        marginBottom: theme.spacing(2),
        overflow: 'auto',
        marginTop: theme.spacing(5),
        marginTop: '30px',
    },

    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        "&.MuiDrawer-paperAnchorDockedRight": {
            top: '160px'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        height: 'max-content',
        "&.MuiDrawer-paperAnchorDockedRight": {
            top: '9%',
            right: '45px'
        }
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const FleetTable = ({ userOrg, userPermissions }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = useState();
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const classes = useTableStyles();
    const drawerClasses = useDrawerStyles();
    const [page, setPage] = useState(0);
    const [rows, setFleetRows] = useState([]);
    const [openDeleteDialog, setDeleteDialog] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [fleet, setFleet] = useState({
        id: "",
        name: "",
        createdDateUtc: "",
        vehiclesCount: "",
        editMode: false
    });

    const [searchInput, setSearchInput] = React.useState('');

    const [showSearchBar, setShowSearchBar] = useState(false);
    const imgsMagnifyIcon = [magnifyIcon, magnifyicon_hover];

    const [iconMagnify, setIconMagnify] = useState(imgsMagnifyIcon[0]);
    const [openIconMagnify, setOpenIconMagnify] = useState(false);
    const [toggleSearchBar, setToggleSearchBar] = useState(false);
    const [enableSelect, setEnableSelect] = useState(true);

    const columns = useMemo(
        () => [
            { Header: 'Fleet Name', accessor: "name", disableGlobalFilter: true, },
            { Header: 'Created', accessor: "createdDateUtc", disableGlobalFilter: true, },
            { Header: 'Fleet Size', accessor: "vehiclesCount", disableGlobalFilter: true, }
        ],
        [],
    );

    useEffect(() => {
        initializeFleets();
        setDeleteDialog(false);
    }, []);

    useEffect(() => {
        if (searchInput?.length === 0) {
            initializeFleets();
        }

    }, [searchInput]);

    const initializeFleets = () => {
        let fleetArray = [];
        let fleetIdArray = [];

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data.viewFleetIds.length > 0) {
                    data.viewFleetIds.map(item => {
                        fleetIdArray.push(item);
                    })};

                GetFleetPaged({ pageNumber: newPageVariable, pageSize: rowsPerPage, fleetIdArray })
                    .then(data => {
                        if (data && Array.isArray(data.fleets)) {
                            data.fleets.map(fleet => {
                                setTotalPages(data.totalPages)
                                fleetArray.push(
                                    {
                                        id: fleet.id,
                                        name: fleet.name,
                                        createdDateUtc: FormmatDate(fleet.createdDateUtc),
                                        vehiclesCount: fleet.vehiclesCount
                                    }
                                );
                            });
                        }
                        setFleetRows(fleetArray);
                    });
            });
    };

    const handleClickAdd = () => {
        setOpen(true);
        setFleet({
            ...fleet, id: "", name: "", createdDateUtc: "", vehiclesCount: "", editMode: false
        });
    };

    const handleClickEdit = () => {
        if (selectedRow !== null) {
            setFleet({
                ...fleet, id: selectedRow.id, name: selectedRow.name,
                createdDateUtc: selectedRow.createdDateUtc, vehiclesCount: selectedRow.vehiclesCount, editMode: true
            });
            setOpen(true);
        }
    };

    const handleDeleteDialogContinue = () => {
        DeleteSelectedFleet(selected);
    }

    const handleDeleteDialogCancel = () => {
        setDeleteDialog(false)
        setSelected('');
    }

    const handleClickDelete = () => {
        if (selected.length !== 0) {
            setDeleteDialog(true);
        }
    };

    const DeleteSelectedFleet = (selectedValue) => {
        if (selectedValue.id === selectedRow.id) {
            DeleteFleet(selectedValue.id).then(data => {
                initializeFleets();
                setSelected('');
                setDeleteDialog(false);
                setEnableSelect(true);
            })
        }
    };

    const saveFleet = () => {
        fleet.editMode ?
            UpdateFleet({
                "id": fleet.id,
                "name": fleet.name
            })
                .then(data => {
                    initializeFleets();
                    setSelected('');
                    resetFleetVehicle();
                })
            :
            CreateFleet({
                "name": fleet.name,
                "createdByOrganizationId": userOrg.id
            })
                .then(data => {
                    initializeFleets();
                    setSelected('');
                    resetFleetVehicle();
                });
    };

    const handleClose = () => {
        setOpen(false);
        setEnableSelect(false);
    };

    const handleDone = () => {
        setOpen(false);
        saveFleet();
        setEnableSelect(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFleet({ ...fleet, [name]: value });
    };

    const handleClick = (event, name, row) => {
        let newSelected = selected;

        if (name !== selected) {
            newSelected = name;
        }
        setSelected(newSelected);
        setSelectedRow({
            id: row.id,
            name: row.name,
            createdDateUtc: row.createdDateUtc,
            vehiclesCount: row.vehiclesCount
        });
        setOpen(false);
    };

    // const handleChangePage = (event, newPage) => {
    //     newPageVariable = newPage + 1
    //     setPage(newPage);
    //     initializeFleets();

    // };

    // const handleChangeRowsPerPage = (event) => {
    //     newPageVariable = 1
    //     rowsPerPage = parseInt(event.target.value, 10);
    //     initializeFleets();
    // };

    const resetFleetVehicle = () => {
        setFleet({
            id: "",
            name: "",
            createdDateUtc: "",
            vehiclesCount: "",
            editMode: false
        });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const selectedRowValues = (row) => {
        setSelectedRow(row.original)
        setSelected(row.original)
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };

    //................. Search.....................//

    const handleSearchInput = (event) => {
        setSearchInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInput('');
        }
    };

    const handleSearch = () => {
        if (searchInput) {
            let fleetArray = [];

            SearchFleet(searchInput.trim())
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(fleet => {
                            fleetArray.push(
                                {
                                    id: fleet.id,
                                    name: fleet.name,
                                    createdDateUtc: FormmatDate(fleet.createdDateUtc),
                                    vehiclesCount: fleet.vehiclesCount
                                }
                            );
                        });
                    }
                    setFleetRows(fleetArray);
                });
        }
    };

    const handleCloseMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[0]);
        setOpenIconMagnify(false);
    };

    const handleOpenMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[1]);
        setOpenIconMagnify(true);
    };

    const handleCloseSearchbar = () => {
        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
    };

    const handleSearchbar = () => {
        setShowSearchBar(false);
        setToggleSearchBar(false);
        setSearchInput('');
    };

    const matchesPermission = userPermissions.includes(permission.Administration_Administrator);

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Col md={12} lg={12}>
            <AlertDialog
                open={openDeleteDialog}
                message={`You are about to delete: ${selectedRow.name} ?`}
                name={selectedRow.name}
                handleDeleteDialogCancel={handleDeleteDialogCancel}
                handleDeleteDialogContinue={handleDeleteDialogContinue}
            />

            <Drawer
                variant="permanet"
                anchor="right"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                classes={{
                    paper: drawerClasses.drawerPaper,
                }}
            >
                <FleetAddEdit fleet={fleet} onChange={handleChange}
                    handleClose={handleClose} handleDone={handleDone}
                />
            </Drawer>

            <div id="tablecardRolePage">
                <Row className='headerStyleRolePage'>
                    <Col lg="6" md="6" sm="6" xs="6" ></Col>
                    <Col lg="2" md="2" sm="2" xs="2">
                        {
                            showSearchBar ?
                                <div className='searchStyleOrg'>
                                    <SearchField
                                        handleSearchbar={handleSearchbar}
                                        handleSearch={handleSearch}
                                        handleSearchInput={handleSearchInput}
                                        onKeyPress={textFieldKeypress}
                                        value={searchInput}
                                        priceBucketActiveTab='true'
                                        autoFocus='true'
                                        placeholder={'Fleet Name'}
                                    />
                                </div>
                                :
                                <Tooltip
                                    title="open search"
                                    onOpen={handleOpenMagnifyIcon}
                                    onClose={handleCloseMagnifyIcon}
                                    style={{ zIndex: 200 }}
                                >
                                    <IconButton
                                        onClick={handleCloseSearchbar}
                                    >
                                        <img
                                            src={iconMagnify}
                                            className="magnifyIconOrg"
                                        />
                                    </IconButton>
                                </Tooltip>
                        }
                    </Col >

                    <Col lg="2" md="2" sm="2" xs="2" className='columnstyle' >
                        {
                            matchesPermission &&
                            <div className='toolbarstyleOrg'>
                                <EnhancedTableToolbar
                                    selectedRowId={selected}
                                    handleClickDelete={handleClickDelete}
                                    handleClickEdit={handleClickEdit}
                                    handleClickAdd={handleClickAdd}
                                    enableSelect={enableSelect}
                                />
                            </div>
                        }
                    </Col>

                    <Col lg="2" md="2" sm="2" xs="2"></Col>
                </Row>
                <>
                    <Fade bottom duration={1500}>
                        <div className="tableFixHeadAdmin">
                            {
                                <ReactTableBase
                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                    columns={columns}
                                    data={rows}
                                    tableConfig={tableConfig}
                                    onClick={(event) => handleClick(event, rows.email, rows)}
                                    onRowSelected={handleClick}
                                    isSelected={isSelected}
                                    selectedRowValues={selectedRowValues}
                                    setEnableSelect={setEnableSelect}
                                />
                            }
                        </div>
                    </Fade>
                </>
            </div>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(FleetTable)