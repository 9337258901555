import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import ReactTableBaseVehiclePage from 'src/components/tables/newtable/table/ReactTableBaseVehiclePage';
import { makeStyles } from '@material-ui/core/styles';
import { SearchByExtendedSearch, FleetAssetsExtendedSearchKpis, GetVehicleModelYears, GetOrderStatusList, GetVehiclePriceChart } from 'src/services/vehicle-pages-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserGraphData, selectUserPermissions, selectUserParentOrganization } from 'src/redux/user/user.selectors';
import { GetFleets } from 'src/services/fleet-http.service';
import { GetFleetTypes } from 'src/services/fleetType-http.service';
import { UpdateUsedCar } from 'src/services/sales-settings-http.service'
import { FormmatDate, } from 'src/utils/DateFormatter';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';
import { Typography } from '@material-ui/core';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import swal from 'sweetalert';
import SearchButton from 'src/components/buttons/search-button-component';
import DetailsMainPage from 'src/components/vehicleDetails/DetailsMainPage';
import CSVExport from 'src/services/csv-export.service';
import Modal from "@mui/material/Modal";
import './AllCarsList.css';
import FormSelect from 'src/components/form-elements/custom-select.component';
import FadeLoader from "react-spinners/FadeLoader";
import Tooltip from '@material-ui/core/Tooltip';
import UsedCarStockCard from 'src/containers/advancedSearchComponents/UsedCarStockCard';
import UsedCarReadyForSaleCard from 'src/containers/advancedSearchComponents/UsedCarReadyForSaleCard';
import RVBelowLCCard from 'src/containers/advancedSearchComponents/RVBelowLCCard';
import AgeRangeCCard from 'src/containers/advancedSearchComponents/AgeRangeCCard';
import RVRangeCard from 'src/containers/advancedSearchComponents/RVRangeCard';
import FleetTypeCard from 'src/containers/advancedSearchComponents/FleetTypeCard';
import VehicleColourCard from 'src/containers/advancedSearchComponents/VehicleColourCard';
import EngineCard from 'src/containers/advancedSearchComponents/EngineCard';
import ModelYearCard from 'src/containers/advancedSearchComponents/ModelYearCard';
import MarketCard from 'src/containers/advancedSearchComponents/MarketCard';
import OrderTypeCard from 'src/containers/advancedSearchComponents/OrderTypeCard';
import TowbarCard from 'src/containers/advancedSearchComponents/TowbarCard';
import WorkOrdersCard from 'src/containers/advancedSearchComponents/WorkOrdersCard';
import { permission } from 'src/auth/permissions';
import ManualCaseCreationIcon from 'src/assets/img/vehicles/ManualCaseCreationIcon.png';
import ManualCaseCreationIconGreen from 'src/assets/img/vehicles/ManualCaseCreationIconGreen.png';
import Fade from 'react-reveal/Fade';
import SearchField from 'src/components/buttons/search-icon-button-new.component';
import magnifyicon_hover from 'src/assets/img/vehicles/magnifyicon_hover.png';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
import IconButton from "@mui/material/IconButton";
import scroll_down from 'src/assets/img/vehicles/scroll_down.png';
import scroll_up from 'src/assets/img/vehicles/scroll_up.png';
import { GetOrganizationById } from "src/services/organization-http.service";

let rowsPerPage = 200;
let newPageVariable = 1;
let otdFleetType = 'Yes';
let vin = '';

let modelData = {
    vin: '',
    reservedPrice: '',
    bottomlinePrice: '',
    rvGross: '',
    priceAfterAdjustment: '',
    color: '',
    odometerKm: '',
    market: '',
    age: '',
    forecastedDefleetDateUtc: '',
    usedCarIsB2B: '',
    primaryDriverCustomerNumber: ''
}

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    drawarModal: {
        width: '100%',
        flexShrink: 0,
        marginTop: '150px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    },
    root: {
        overflow: 'auto',
        height: '675px',
        '@media (min-width: 600px) and (max-width: 770px)': {
            marginTop: '-130px',
            marginLeft: '-120px',
            marginRight: '10px',
            height: '677px',
        },
        '@media (min-width: 771px) and (max-width: 1000px)': {
            marginTop: '-125px',
            marginLeft: '-115px',
            marginRight: '24px',
            height: '705px',
        },
        '@media (min-width: 1001px) and (max-width: 1110px)': {
            marginTop: '-80px',
            marginLeft: '20px',
            marginRight: '-75px',
            height: '365px',
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            marginTop: '-140px',
            marginLeft: '-160px',
            marginRight: '30px',
            height: '507px',
        },
        '@media (min-width: 1026px) and (max-width: 1077px)': {
            marginTop: '-160px',
            marginLeft: '-140px',
            marginRight: '30px',
            height: '550px',
        },
        '@media (min-width: 1111px) and (max-width: 1275px)': {
            marginTop: '-70px',
            marginLeft: '-45px',
            marginRight: '-46px',
            height: '348px',
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            marginTop: '-55px',
            marginLeft: '-20px',
            marginRight: '-55px',
            height: '423px',
        },
        '@media (min-width: 1281px) and (max-width: 1400px)': {
            marginTop: '-120px',
            marginLeft: '20px',
            marginRight: '-75px',
            height: '470px',
        },
        '@media (min-width: 1401px) and (max-width: 1420px)': {
            marginTop: '-135px',
            marginLeft: '10px',
            marginRight: '-85px',
            height: '443px',
        },
        '@media (min-width: 1421px) and (max-width: 1450px)': {
            marginTop: '-115px',
            marginLeft: '10px',
            marginRight: '-85px',
            height: '463px',
        },
        '@media (min-width: 1451px) and (max-width: 1600px)': {
            marginTop: '-128px',
            marginLeft: '-5px',
            marginRight: '-86px',
            height: '512px',
        },
        '@media (min-width: 1801px) and (max-width: 1872px)': {
            marginTop: '-229px',
            marginLeft: '-25px',
            marginRight: '-8px',
            height: '656px',
        },
        '@media (min-width: 1873px) and (max-width: 1919px)': {
            marginTop: '-229px',
            marginLeft: '-25px',
            marginRight: '-8px',
            height: '632px',
        },
        '@media (min-width: 1920px) and (max-width: 2600px)': {
            marginTop: '-229px',
            marginLeft: '-25px',
            marginRight: '-8px',
            height: '618px',
        },
        '@media (min-width: 2601px) and (max-width: 4600px)': {
            marginTop: '-305px',
            marginLeft: '110px',
            marginRight: '-151px',
            height: '650px',
        },
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        },
        overflowX: 'hidden',
        marginTop: '-120px',
        marginLeft: '-25px',
        marginRight: '-15px',
        "&:focus": {
            overflowY: 'scroll',
        },
    },
    rotateIcon: {
        marginTop: '-100px',
        cursor: 'pointer',
        marginLeft: '-15px',
        '@media (min-width: 1451px) and (max-width: 1600px)': {
            marginLeft: '90px',
        },
        '@media (min-width: 1401px) and (max-width: 1450px)': {
            marginLeft: '90px',
        },
        '@media (min-width: 2601px) and (max-width: 4600px)': {
            marginLeft: '250px',
        },
        '@media (min-width: 1276px) and (max-width: 1280px)': {
            marginLeft: '45px',
        },
        '@media (min-width: 1111px) and (max-width: 1275px)': {
            marginLeft: '1px',
        },
        '@media (min-width: 1001px) and (max-width: 1024px)': {
            marginLeft: '-190px',
        },
        '@media (min-width: 1025px) and (max-width: 1077px)': {
            marginLeft: '-170px',
        },
        '@media (min-width: 771px) and (max-width: 1000px)': {
            marginLeft: '-130px',
        },
        '@media (min-width: 600px) and (max-width: 770px)': {
            marginLeft: '-130px',
        },
        animation: "$bounce 2s linear infinite"
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    }
}));

const M2MTable = ({ userOrg, userParentOrg, userMarketIds, selectTable = false, listviewyype = 2, userPermissions, multiMarketSelect = true, setToggleTabBar }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isColumnHidden, setIsColumnHidden] = useState(false);
    const [isEnableSave, setIsEnableSave] = useState(false);
    const [showExtendedSearchIcon, setShowExtendedSearchIcon] = useState(false);
    const [openDetailsPage, setOpenDetailsPage] = useState(false);

    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [selected, setSelected] = useState('');
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);

    const [rows, setVehicleFleet] = useState([]);

    const [pageSelectAll, setPageSelectAll] = useState([]);
    const [isPageSelectAll, setIsPageSelectAll] = useState(false);

    const [selectedRow, setSelectedRow] = useState({});
    const [unSuccessfulVINs, setUnsuccessfulVINs] = useState([]);

    const [presSelectedFleetTypes, setPresSelectedFleetTypes] = useState([]);
    const [presSelectedMarketIds, setPresSelectedMarketIds] = useState([]);

    let selectedRowVehiclePostion = {}
    let queryParams = {}

    let fleetTypes = []
    let marketIds = []

    const [earMarked, setEarMarked] = useState([]);
    const [dialogMessage, setDialogMessage] = useState('');
    const [isErrorDialog, setErrorDialogFlag] = useState(false);
    const drawerClasses = useDrawerStyles();
    const [shouldRenderMap, setShouldRenderMap] = useState(false)
    const [vehiclePosition, setVeiclePosition] = useState({
        "vin": "",
        "latitude": 0,
        "longitude": 0,
        "altitude": 0,
        "canBeTrusted": false,
        "vehicleUpdatedAt": ""
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
    const [selectedFleetType, setSelectedFleetType] = useState({ key: "NONE", value: "" })
    const [queryParamsAge, setQueryParamsAge] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })

    const [queryParamsMarket, setQueryParamsMarket] = useState('');
    const [queryParamsMarketIds, setQueryParamsMarketIds] = useState([]);

    const [queryParamsFleetType, setQueryParamsFleetType] = useState('');
    const [queryParamsFleetTypeIds, setQueryParamsFleetTypeIds] = useState([]);

    const [queryParamsModelYear, setQueryParamsModelYear] = useState([]);

    const [queryParamsModelYearSelect, setQueryParamsModelYearSelect] = useState([]);

    const [modelYearToggle, setModelYearToggle] = useState(false);

    const [orderStatusToggle, setOrderStatusToggle] = useState(false);

    const [queryParamsVin, setQueryParamsVin] = useState('');
    const [queryParamsLicensePlate, setQueryParamsLicensePlate] = useState('');
    const [queryParamsOrderType, setQueryParamsOrderType] = useState(['B2B', 'B2C', '']);
    const [queryParamsOrderStatus, setQueryParamsOrderStatus] = useState([]);
    const [queryParamsOrderStatusSelect, setQueryParamsOrderStatusSelect] = useState([]);

    const [queryParamsOrderNumber, setQueryParamsOrderNumber] = useState('');
    const [queryParamsPNOCode, setQueryParamsPNOCode] = useState('');
    const [queryParamsEngine, setQueryParamsEngine] = useState(['HEV', 'PHEV']);

    const [queryParamsAvailableForSales, setQueryParamsAvailableForSales] = useState(false);
    const [queryParamsCommercialCostMonthIndex, setQueryParamsCommercialCostMonthIndex] = useState('');

    const [queryParamsColor, setQueryParamsColor] = useState(['Blue', 'Black']);

    const [queryParamsRvBelowLandedCost, setQueryParamsRvBelowLandedCost] = useState('');
    const [queryParamsCarSwapInXMonths, setQueryParamsCarSwapInXMonths] = useState('');

    const [queryParamsNoOpenWorkOrders, setQueryParamsNoOpenWorkOrders] = useState(false);

    const [queryParamsUsedCarStock, setQueryParamsUsedCarStock] = useState('');
    const [queryParamsURvRange, setQueryParamsRvRange] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    });
    const [queryParamsUsedInventoryStatus, setQueryParamsInventoryStatus] = useState('');
    const [queryParamsFilteredTowbar, setQueryParamsFilteredTowbar] = useState(false);

    const [queryParamsLastMileStatus, setQueryParamsLastmileStatus] = useState('');
    const [queryParamsProvisionStatus, setQueryParamsProvisionStatus] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [closeB2BSelection, setCloseB2BSelection] = useState(false);

    const [marketconfig, setMarketconfig] = useState(false);

    const [fleetTypeIdsToggle, setFleetTypeIdsToggle] = useState([]);

    const [fleetTypeIdsconfig, setFleetTypeIdscongfig] = useState(false);
    const [iconDirection, setIconDirection] = useState(false);

    const [userCarStockCount, setUsedCarStockCount] = useState(null);
    const [availableForSalesCount, setAvailableForSales] = useState(null);
    const [rvBelowLCCount, setRVBelowLC] = useState(null);
    const [towBarCount, setTowBarCount] = useState(null);
    const [noOpenWorkOrderCount, setNoOpenWorkOrderCount] = useState(null);
    const [usedCarToggle, setUsedCarToggle] = useState(false);

    const [showSearchBar, setShowSearchBar] = useState(false);

    const imgs = [ManualCaseCreationIcon, ManualCaseCreationIconGreen];

    const imgsMagnifyIcon = [magnifyIcon, magnifyicon_hover];

    const [iconMagnify, setIconMagnify] = useState(imgsMagnifyIcon[0]);
    const [openIconMagnify, setOpenIconMagnify] = useState(false);

    const [counterPagination, setCounterPagination] = useState(1);
    const [disablePagination, setDisablePagination] = useState(false);

    const [vehicleDetailsChartValues, setVehicleDetailsChartValues] = useState({});

    const [toggleSearchBar, setToggleSearchBar] = useState(false);

    const div = useRef(null);

    const columns = useMemo(
        () => [
            { Header: 'License Plate', accessor: "licensePlate", disableGlobalFilter: true },
            { Header: 'VIN', accessor: "vin", disableGlobalFilter: true },
            { Header: 'PNO', accessor: "pnoCode", disableGlobalFilter: true },
            { Header: 'Model', accessor: "model", disableGlobalFilter: true, },
            { Header: 'Model Year', accessor: "modelYear", disableGlobalFilter: true },
            { Header: 'Engine Type', accessor: "engineType", disableGlobalFilter: true, },
            { Header: 'OBC', accessor: "obcVersion", disableGlobalFilter: true, },
            { Header: 'Color', accessor: "color", disableGlobalFilter: true, },
            { Header: 'Towbar', accessor: "hasTowbar", disableGlobalFilter: true },
            { Header: 'Odometer', accessor: "odometerKm", disableGlobalFilter: true },
            { Header: 'RV [gross]', accessor: "rvGross", disableGlobalFilter: true },
            { Header: 'Reserved Price', accessor: "reservedPrice", disableGlobalFilter: true },
            { Header: 'Bottom Line Price', accessor: "bottomlinePrice", disableGlobalFilter: true },
            { Header: 'Registered', accessor: "registeredDateUtc", disableGlobalFilter: true },
            { Header: 'Age [Months]', accessor: "age", disableGlobalFilter: true },
            { Header: 'Buy My Car Price', accessor: "priceAfterAdjustment", disableGlobalFilter: true },
            { Header: 'Est. De-fleet', accessor: "forecastedDefleetDateUtc", disableGlobalFilter: true },
            { Header: 'Inventory Status', accessor: "inventoryState", disableGlobalFilter: true },
            { Header: 'Open Work Orders', accessor: "openWorkOrders", disableGlobalFilter: true },
            { Header: 'Used Car Stock', accessor: "usedCar", disableGlobalFilter: true },
            { Header: 'Used Car B2B', accessor: "usedCarIsB2B", disableGlobalFilter: true },
            { Header: 'Market', accessor: "market", disableGlobalFilter: true },
            { Header: 'Order Type', accessor: "orderType", disableGlobalFilter: true },
            { Header: 'Primary Driver', accessor: "primaryDriverCustomerNumber", disableGlobalFilter: true },
            { Header: 'Order Number', accessor: "orderNumber", disableGlobalFilter: true },
            { Header: 'Below Landed Cost', accessor: "belowLandedCost", disableGlobalFilter: true },
            // { Header: 'Order Status', accessor: "orderStatus", disableGlobalFilter: true }
        ],
        [],
    );

    useEffect(() => {
        if (totalItems <= rows.length) {
            setDisablePagination(true);
        }
    }, [rows.length, totalItems]);

    useEffect(() => {
        setModelYears();
        setOderStatus();
        marketIds = userMarketIds;
        setPresSelectedMarketIds(marketIds);
        setOpenDialog(false);
        if (queryParamsModelYearSelect.length !== 0 && queryParamsOrderStatusSelect.length !== 0) {
            setQueryParamsVin('')
            initializeFleetVehicles(marketIds);
            AdvancedSearchCountQuery(marketIds);
        }
        if (queryParamsOrderType.length == 0) {
            setQueryParamsOrderType([])
        }
    }, [queryParamsAvailableForSales, queryParamsCommercialCostMonthIndex, queryParamsOrderStatusSelect.length, queryParamsModelYearSelect.length, queryParamsUsedCarStock, queryParamsRvBelowLandedCost, queryParamsURvRange, queryParamsFleetTypeIds.length, queryParamsColor, queryParamsEngine, queryParamsMarketIds, queryParamsOrderType.length, queryParamsOrderStatus.length, queryParamsAge, queryParamsModelYear.length, queryParamsFilteredTowbar, queryParamsCarSwapInXMonths, queryParamsNoOpenWorkOrders]);

    const setModelYears = () => {
        let modelYearArray = []

        GetVehicleModelYears().then(data => {
            Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

                modelYear => {
                    modelYearArray.push(
                        modelYear.value
                    );
                })
                : console.log("Error Occured");
            setQueryParamsModelYearSelect(modelYearArray);
        });
    };

    const setOderStatus = () => {
        let orderStatusArray = []

        GetOrderStatusList().then(data => {
            Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

                orderStatus => {
                    orderStatusArray.push(
                        orderStatus.value
                    );
                })
                : console.log("Error Occured");
            setQueryParamsOrderStatusSelect(orderStatusArray);
        });
    };

    const updateQueryParamsMarketIds = (updatedMarketIds) => {
        setQueryParamsMarketIds(updatedMarketIds);
        marketIds = [];
    };

    const getVehicleDetailsChartValues = (vin) => {
        GetVehiclePriceChart(vin).then((data) => {
            if (data.ok == true) {
                setVehicleDetailsChartValues(data)
            }
        })
    };

    const initializeFleetVehicles = (marketIds) => {
        let fleetIds = [];
        let vehicleFleetArray = [];
        newPageVariable = 1;
        setDisablePagination(false);
        setCounterPagination(0);

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: '' }
        queryParams = { ...queryParams, licensePlate: '' }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, filterByTowbar: queryParamsFilteredTowbar }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, noOpenWorkOrder: queryParamsNoOpenWorkOrders }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userParentOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                                setFleetTypeIdsToggle(fleetTypes)
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: newPageVariable, pageSize: rowsPerPage, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 && !fleetTypeIdsconfig ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 && !marketconfig ? marketIds : queryParamsMarketIds, otdFleetType })
                                .then(data => {

                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages)
                                        setTotalItems(data.totalItems ?? 0)
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push(
                                                {
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    pnoCode: vehicle.pnoCode,
                                                    model: vehicle.model,
                                                    modelYear: vehicle.modelYear,
                                                    engineType: vehicle.engineType,
                                                    obcVersion: vehicle.obcVersion,
                                                    color: vehicle.color,
                                                    hasTowbar: vehicle.hasTowbar,
                                                    odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                    rvGross: formatAndVerifyNumericValue(vehicle.rvDetails.rvGross),
                                                    reservedPrice: formatAndVerifyNumericValue(vehicle.rvDetails.reservedPrice),
                                                    bottomlinePrice: formatAndVerifyNumericValue(vehicle.rvDetails.bottomlinePrice),
                                                    registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                    age: vehicle.age,
                                                    priceAfterAdjustment: vehicle.rvDetails.priceAfterAdjustment == 0 ? null : formatAndVerifyNumericValue(vehicle.rvDetails.priceAfterAdjustment),
                                                    forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                    inventoryState: vehicle.inventoryState,
                                                    openWorkOrders: vehicle.openWorkOrders,
                                                    usedCar: vehicle.usedCar,
                                                    usedCarIsB2B: vehicle.usedCarIsB2B,
                                                    market: vehicle.market,
                                                    orderType: vehicle.orderType,
                                                    primaryDriverCustomerNumber: vehicle.primaryDriverCustomerNumber,
                                                    orderNumber: vehicle.orderNumber,
                                                    filterByTowbar: vehicle.filterByTowbar,
                                                    belowLandedCost: vehicle.rvDetails.belowLandedCost,
                                                    orderStatus: vehicle.orderStatus
                                                }
                                            );
                                        });
                                    }
                                    setDataLoaded(true);
                                    setVehicleFleet(vehicleFleetArray);
                                });
                        })
                }
            })
    };

    const AdvancedSearchCountQuery = (marketIds) => {
        let fleetIds = [];

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, filterByTowbar: queryParamsFilteredTowbar }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, noOpenWorkOrder: queryParamsNoOpenWorkOrders }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userParentOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            FleetAssetsExtendedSearchKpis({ ...queryParams, pageNumber: newPageVariable, pageSize: rowsPerPage, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 && !fleetTypeIdsconfig ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 && !marketconfig ? marketIds : queryParamsMarketIds, otdFleetType })
                                .then(data => {
                                    if (data !== undefined) {
                                        if (data.ok == true) {
                                            setUsedCarStockCount(data.userCarStock);
                                            setAvailableForSales(data.availableForSales);
                                            setRVBelowLC(data.rvBelowLC);
                                            setTowBarCount(data.towBar);
                                            setNoOpenWorkOrderCount(data.noOpenWorkOrderCount);
                                        }
                                        setDataLoaded(true);

                                    }

                                });
                        })
                }
            })
    };

    /////////////////////////////////////// To load more data in the table

    const PaginationFleetVehicles = (marketIds, page) => {
        let fleetIds = [];
        let vehicleFleetArray = [];

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, filterByTowbar: queryParamsFilteredTowbar }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, noOpenWorkOrder: queryParamsNoOpenWorkOrders }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }


        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userParentOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                                setPresSelectedFleetTypes(fleetTypes)
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: page, pageSize: rowsPerPage, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 && !marketconfig ? marketIds : queryParamsMarketIds, otdFleetType })
                                .then(data => {

                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push(
                                                {
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    pnoCode: vehicle.pnoCode,
                                                    model: vehicle.model,
                                                    modelYear: vehicle.modelYear,
                                                    engineType: vehicle.engineType,
                                                    obcVersion: vehicle.obcVersion,
                                                    color: vehicle.color,
                                                    hasTowbar: vehicle.hasTowbar,
                                                    odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                    rvGross: formatAndVerifyNumericValue(vehicle.rvDetails.rvGross),
                                                    reservedPrice: formatAndVerifyNumericValue(vehicle.rvDetails.reservedPrice),
                                                    bottomlinePrice: formatAndVerifyNumericValue(vehicle.rvDetails.bottomlinePrice),
                                                    registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                    age: vehicle.age,
                                                    priceAfterAdjustment: vehicle.rvDetails.priceAfterAdjustment == 0 ? null : formatAndVerifyNumericValue(vehicle.rvDetails.priceAfterAdjustment),
                                                    forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                    inventoryState: vehicle.inventoryState,
                                                    openWorkOrders: vehicle.openWorkOrders,
                                                    usedCar: vehicle.usedCar,
                                                    usedCarIsB2B: vehicle.usedCarIsB2B,
                                                    market: vehicle.market,
                                                    orderType: vehicle.orderType,
                                                    orderNumber: vehicle.orderNumber,
                                                    primaryDriverCustomerNumber: vehicle.primaryDriverCustomerNumber,
                                                    filterByTowbar: vehicle.filterByTowbar,
                                                    belowLandedCost: vehicle.rvDetails.belowLandedCost
                                                }
                                            );
                                        });
                                    }
                                    setVehicleFleet([...rows, ...vehicleFleetArray]);
                                });
                        })
                }
            })
    };

    const header = [
        [
            'License Plate',
            'VIN',
            'PNO',
            'Model',
            'Model Year',
            'Engine Type',
            'OBC',
            'Color',
            'Towbar',
            'Odometer',
            'RV [gross]',
            'Reserved Price',
            'Bottom Line Price',
            'Registered',
            'Age [Months]',
            'Buy My Car Price',
            'Est. De-fleet',
            'Inventory Status',
            'Open Work Orders',
            'Used Car Stock',
            'Used Car B2B',
            'Market',
            'Order Type',
            'Primary Driver',
            'Order Number'
        ]
    ]

    //////////////////////////////////////// Download in Excel format

    const handleClickDownloadExcel = () => {
        setDownloadSpinner(true);

        let fleetIds = [];
        let vehicleFleetArray = [];
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, noOpenWorkOrder: queryParamsNoOpenWorkOrders }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userParentOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 0, pageSize: 0, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 && !fleetTypeIdsconfig ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 ? marketIds : queryParamsMarketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: vehicle.odometerKm,
                                                rvGross: vehicle.rvDetails.rvGross,
                                                reservedPrice: vehicle.rvDetails.reservedPrice,
                                                bottomlinePrice: vehicle.rvDetails.bottomlinePrice,
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                age: vehicle.age,
                                                priceAfterAdjustment: vehicle.rvDetails.priceAfterAdjustment == 0 ? null : formatAndVerifyNumericValue(vehicle.rvDetails.priceAfterAdjustment),
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                inventoryState: vehicle.inventoryState,
                                                openWorkOrders: vehicle.openWorkOrders,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                orderType: vehicle.orderType,
                                                primaryDriverCustomerNumber: vehicle.primaryDriverCustomerNumber,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })

                }
            })

        const initiateDownload = (row) => {

            const fileName = 'L&C Assets';

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            const fileExtension = '.xlsx';
            const exportToExcel = (row, fileName) => {

                const ws = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(ws, header);

                XLSX.utils.sheet_add_json(ws, row, { origin: 'A2', skipHeader: true });

                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, fileName + fileExtension);

            }
            exportToExcel(row, fileName);
        }
        setOpenModal(false);
    }

    //////////////////////////////////// Download in CSV format

    const handleClickDownloadCSV = () => {
        setDownloadSpinner(true);

        let fleetIds = []
        let vehicleFleetArray = [];
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }

        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, noOpenWorkOrder: queryParamsNoOpenWorkOrders }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }

        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userParentOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 0, pageSize: 0, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 ? marketIds : queryParamsMarketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: vehicle.odometerKm,
                                                rvGross: vehicle.rvDetails.rvGross,
                                                reservedPrice: vehicle.rvDetails.reservedPrice,
                                                bottomlinePrice: vehicle.rvDetails.bottomlinePrice,
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                age: vehicle.age,
                                                priceAfterAdjustment: vehicle.rvDetails.priceAfterAdjustment == 0 ? null : formatAndVerifyNumericValue(vehicle.rvDetails.priceAfterAdjustment),
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                inventoryState: vehicle.inventoryState,
                                                openWorkOrders: vehicle.openWorkOrders,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                orderType: vehicle.orderType,
                                                primaryDriverCustomerNumber: vehicle.primaryDriverCustomerNumber,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })
                }
            })


        const initiateDownload = (row) => {
            CSVExport(header, row, 'L&C Assets')
        }
        setOpenModal(false);
    };

    const handleCloseDownload = () => {
        setOpenModal(false);
    };

    ////////////////////////////////////////////////////////////////////// To search using search bar in landing page

    const handleExtendedSearch = () => {
        setDataLoaded(false);

        setShowSearchBar(true);
        setToggleSearchBar(true);

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }

        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, noOpenWorkOrder: queryParamsNoOpenWorkOrders }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }

        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        console.log(queryParams)

        let fleetIds = []
        let vehicleFleetArray = [];
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        GetOrganizationById(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data.viewFleetIds)) {
                    data.viewFleetIds.map(item => {
                        fleetIds.push(item);
                    })
                    GetFleetTypes(userParentOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            queryParams = { ...queryParams, pageNumber: 1, pageSize: rowsPerPage, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 ? marketIds : queryParamsMarketIds, otdFleetType }
                            SearchByExtendedSearch(queryParams)
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages)
                                        setTotalItems(data.totalItems ?? 0)
                                        newPageVariable = data.currentPage;
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                rvGross: formatAndVerifyNumericValue(vehicle.rvDetails.rvGross),
                                                reservedPrice: formatAndVerifyNumericValue(vehicle.rvDetails.reservedPrice),
                                                bottomlinePrice: formatAndVerifyNumericValue(vehicle.rvDetails.bottomlinePrice),
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                age: vehicle.age,
                                                priceAfterAdjustment: vehicle.rvDetails.priceAfterAdjustment == 0 ? null : formatAndVerifyNumericValue(vehicle.rvDetails.priceAfterAdjustment),
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                inventoryState: vehicle.inventoryState,
                                                openWorkOrders: vehicle.openWorkOrders,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                orderType: vehicle.orderType,
                                                primaryDriverCustomerNumber: vehicle.primaryDriverCustomerNumber,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });

                                    }
                                    if (data.totalPages == 0) {
                                        newPageVariable = 1
                                    }
                                    setVehicleFleet(vehicleFleetArray);
                                    setDataLoaded(true);
                                });

                        })
                }
            })
    };

    /////////////////////////////////////////////////////////////////////

    const handleChangePage = () => {
        newPageVariable = newPageVariable + 1;
        setCounterPagination(newPageVariable);
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        PaginationFleetVehicles(marketIds, newPageVariable);
        setOpen(false);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const handleDialogOk = () => {
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        setIsPageSelectAll(false);
        setOpenDialog(false);
        setIsEnableSave(false);
        setIsColumnHidden(false);
        setDataLoaded(false);
        initializeFleetVehicles(marketIds);
    }

    const handleClickSave = () => {
        setUnsuccessfulVINs([]);
        let usedCars = earMarked
        let fleetIds = []
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        swal({
            title: "",
            text: "You are about to update Used Car Stock!",
            className: "swal-modal",
            buttons: {
                cancel: {
                    text: "NO WAY!",
                    data: false,
                    visible: true,
                    className: "swal-buttons",
                    closeModal: true,
                },
                confirm: ({
                    text: "CONTINUE",
                    value: true,
                    visible: true,
                    className: "swal-button",
                    closeModal: true
                })
            },
        }).then((willSave) => {
            if (earMarked.length <= 0 || earMarked == null) {
                setIsColumnHidden(false);
                setIsEnableSave(false);
                setShowExtendedSearchIcon(false);
                setDataLoaded(false);
                initializeFleetVehicles(marketIds);
                setShowSearchBar(false);
                setToggleSearchBar(false);
                setQueryParamsVin('');
                return false;
            }

            if (willSave) {
                GetOrganizationById(userOrg.id)
                    .then(data => {
                        if (data && Array.isArray(data.viewFleetIds)) {
                            data.viewFleetIds.map(item => {
                                fleetIds.push(item);
                            })
                            UpdateUsedCar({ usedCars, fleetIds, marketIds })
                                .then(data => {
                                    if (data.ok == true && data.unsucessfulVins.length === 0) {
                                        setDialogMessage(data.errorMessage)
                                        setUnsuccessfulVINs(data.successfulVins);
                                        setOpenDialog(true);
                                    } else {
                                        // setDialogMessage('Failed Vehicle(s) !');
                                        setDialogMessage(data.errorMessage);
                                        setOpenDialog(true);
                                        setUnsuccessfulVINs(data.unsucessfulVins);
                                    }
                                });
                            setEarMarked([]);
                            initializeFleetVehicles(marketIds);
                            setShowSearchBar(false);
                            setToggleSearchBar(false);
                            setQueryParamsVin('');
                        }
                        setShowExtendedSearchIcon(false);
                    })
            } else {
                setEarMarked([]);
                initializeFleetVehicles(marketIds);
                setIsColumnHidden(false);
                setIsEnableSave(false);
                setShowExtendedSearchIcon(false);
                setShowSearchBar(false);
                setToggleSearchBar(false);
                setQueryParamsVin('');
            }
        });
    }

    const handleCloseB2BButton = () => {
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        setEarMarked([]);
        initializeFleetVehicles(marketIds);
        setIsColumnHidden(false);
        setIsEnableSave(false);
        setShowExtendedSearchIcon(false);
        setShowSearchBar(false);
        setToggleSearchBar(false);
        setQueryParamsVin('');
        setCloseB2BSelection(false);
    }

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    let selectAllArray = [];

    const selectAllRows = (value) => {
        selectAllArray = [];
        setIsPageSelectAll(value.target.checked);

        let filteredPageNumber = pageSelectAll.filter(item => item.pageNumber !== page)
        setPageSelectAll([...filteredPageNumber, { pageNumber: page, selectAllStatus: value.target.checked }]);

        let rowVins = rows.map(item => item.vin);

        rows.map((row) => {
            let status = row.usedCarIsB2B == 'Yes' ? true : false;
            if (status != value.target.checked) {
                earmarkedRowValues(row, true, value.target.checked)
            }
        });

        let filterArray = earMarked.filter((item) => !rowVins.includes(item.id));
        if (filterArray.length > 0) {
            selectAllArray = selectAllArray.concat(filterArray);
        }
        setEarMarked(selectAllArray);
    }

    const earmarkedRowValues = (row, isHeader, isSelectAll) => {
        if (isHeader) {
            selectAllArray.push({ id: row.vin, status: isSelectAll });
        } else {
            let currentRow = earMarked.find((item) => item.id === row.values.vin);
            let usedCar = row.values.usedCarIsB2B == 'Yes' ? true : false;

            if (currentRow) {
                let filterArray = earMarked.filter((item) => item.id != row.values.vin);
                setEarMarked([...filterArray]);
            }
            else {
                setEarMarked([...earMarked, { id: row.values.vin, status: !usedCar }]);
            };
        }
    }

    const selectedRowValues = (row) => {
        setSelectedRow({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            reservedPrice: row.values.reservedPrice,
            bottomlinePrice: row.values.bottomlinePrice,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            priceAfterAdjustment: row.values.priceAfterAdjustment,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            inventoryState: row.values.inventoryState,
            openWorkOrders: row.values.openWorkOrders,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            primaryDriverCustomerNumber: row.values.primaryDriverCustomerNumber,
            orderNumber: row.values.orderNumber
        });
        setSelected({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            reservedPrice: row.values.reservedPrice,
            bottomlinePrice: row.values.bottomlinePrice,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            priceAfterAdjustment: row.values.priceAfterAdjustment,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            inventoryState: row.values.inventoryState,
            openWorkOrders: row.values.openWorkOrders,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            primaryDriverCustomerNumber: row.values.primaryDriverCustomerNumber,
            orderNumber: row.values.orderNumber
        })
        selectedRowVehiclePostion = {
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            reservedPrice: row.values.reservedPrice,
            bottomlinePrice: row.values.bottomlinePrice,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            priceAfterAdjustment: row.values.priceAfterAdjustment,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            inventoryState: row.values.inventoryState,
            openWorkOrders: row.values.openWorkOrders,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            primaryDriverCustomerNumber: row.values.primaryDriverCustomerNumber,
            orderNumber: row.values.orderNumber
        }
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const handleDrawar = () => {
        vin = selectedRowVehiclePostion.vin;
        setOpenDetailsPage(true);
        setToggleTabBar(true);
        setShouldRenderMap(true);
        setToggleSearchBar(!toggleSearchBar);
        modelData = {
            vin: selectedRowVehiclePostion.vin,
            reservedPrice: selectedRowVehiclePostion.reservedPrice,
            bottomlinePrice: selectedRowVehiclePostion.bottomlinePrice,
            rvGross: selectedRowVehiclePostion.rvGross,
            priceAfterAdjustment: selectedRowVehiclePostion.priceAfterAdjustment,
            odometerKm: selectedRowVehiclePostion.odometerKm,
            age: selectedRowVehiclePostion.age,
            color: selectedRowVehiclePostion.color,
            market: selectedRowVehiclePostion.market,
            forecastedDefleetDateUtc: selectedRowVehiclePostion.forecastedDefleetDateUtc,
            usedCarIsB2B: selectedRowVehiclePostion.usedCarIsB2B,
            primaryDriverCustomerNumber: selectedRowVehiclePostion.primaryDriverCustomerNumber
        };
        getVehicleDetailsChartValues(selectedRowVehiclePostion.vin);
    };

    const closeHandleDrawer = () => {
        setOpenDetailsPage(false);
        setToggleTabBar(false);
        setToggleSearchBar(!toggleSearchBar);
    };

    const haandleDropdown = (e) => {
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        const { name, value } = e.target;
        if (value == 1) {
            setIsExpanded(!isExpanded)
            if (isExpanded) {
                setQueryParamsAge({
                    lessThan: false,
                    greaterThan: false,
                    between: false,
                    startValue: "0",
                    endValue: "0",
                })
                setQueryParamsMarket('');
                setQueryParamsFleetType('');
                setQueryParamsVin('');
                setQueryParamsLicensePlate('');
                setQueryParamsOrderType(['B2B', 'B2C', '']);
                setQueryParamsOrderNumber('');
                setQueryParamsPNOCode('');
                setQueryParamsEngine(['HEV', 'PHEV']);
                setQueryParamsColor(['Blue', 'Black']);
                setQueryParamsRvBelowLandedCost('');
                setQueryParamsCarSwapInXMonths('');
                setQueryParamsNoOpenWorkOrders(false);
                setQueryParamsCommercialCostMonthIndex('');
                setQueryParamsUsedCarStock('');
                setQueryParamsRvRange({
                    lessThan: false,
                    greaterThan: false,
                    between: false,
                    startValue: "0",
                    endValue: "0",
                });
                setQueryParamsModelYear(queryParamsModelYearSelect);
                setQueryParamsInventoryStatus('');
                setQueryParamsLastmileStatus('');
                setQueryParamsProvisionStatus('');
                setQueryParamsFleetTypeIds(fleetTypeIdsToggle);
                setQueryParamsMarketIds(marketIds);
                setQueryParamsAvailableForSales(false);
                setQueryParamsFilteredTowbar(false);
                setDisablePagination(false);
                setCounterPagination(1);
            }
            initializeFleetVehicles(marketIds);
            setDataLoaded(false);
            setCloseB2BSelection(false);
        }

        if (value == 2) {
            handleOpenDownload()
        }

        if (value == 3) {
            handleClickIsEditable();
        }
    };

    const handleOpenDownload = () => {
        setDownloadSpinner(false);
        setOpenModal(true);
    };

    const handleClickIsEditable = () => {
        setIsEnableSave(true);
        setIsColumnHidden(true);
        setShowExtendedSearchIcon(true);
        setCloseB2BSelection(true);
    };

    const handleSearchInput = (event) => {
        setQueryParamsVin(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
        }
    };

    const textFieldKeypressSearch = (e) => {
        if (e.key === 'Enter') {
            handleExtendedSearch();
        }
    };

    const handleCloseSearchbar = () => {
        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
    };

    const handleSearchbar = () => {
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        setShowSearchBar(!showSearchBar);
        setToggleSearchBar(!toggleSearchBar);
        setDataLoaded(false);
        setQueryParamsVin('');
        initializeFleetVehicles(marketIds);
    };

    const handleCloseMagnifyIcon = () => {

        setIconMagnify(imgsMagnifyIcon[0]);
        setOpenIconMagnify(false);
    };

    const handleOpenMagnifyIcon = () => {
        setIconMagnify(imgsMagnifyIcon[1]);
        setOpenIconMagnify(true);
    };

    const matchesPermission = userPermissions.includes(permission.Vehicle_Administrator);

    return (
        <Col md={12} lg={12}>
            <VehiclePageDialog
                open={openDialog}
                title={dialogMessage}
                handleDialogOk={handleDialogOk}
                isErrorDialog={isErrorDialog}
                unSuccessfulVINs={unSuccessfulVINs}
                variant='true'
            />

            <Modal
                className={drawerClasses.drawarModal}
                variant="persistent"
                anchor="right"
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleCloseDownload}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <div style={{
                    border: 'none', height: '170px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px'
                }}>
                    <Typography style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", marginTop: '-80px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} variant="h4" id="tableTitle" component="div">
                        Select format
                    </Typography>
                    <Row className='downloadstyle'>
                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Excel'}
                                handleSearch={() => handleClickDownloadExcel(rows)}
                                height='true'
                                loader='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'CSV'}
                                handleSearch={() => handleClickDownloadCSV(rows)}
                                height='true'
                                loader='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Cancel'}
                                handleSearch={handleCloseDownload}
                                height='true'
                                rsa='true'
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>

            <div style={{ marginTop: '87px', marginRight: '-4px', marginLeft: '-17px' }}>
                {openDetailsPage ?
                    <div style={{ marginTop: '220px' }}>
                        <DetailsMainPage
                            vin={vin || queryParamsVin}
                            closeVehicleDetail={closeHandleDrawer}
                            openDetail={isExpanded}
                            selectTable={selectTable}
                            setOpenUsedCar={true}
                            setToggleSearchBar={setToggleSearchBar}
                            modelData={modelData}
                            vehicleDetailsChartValues={vehicleDetailsChartValues}
                        />
                    </div>
                    : <>
                        {
                            isExpanded ?
                                <div id='tablecard' >
                                    <Row className='headerStyleMyOpenCasesLCAssets'>
                                        <Col md={9} sm={9} xs={9} lg={10}>
                                            <Col lg="12" md="12" >
                                                {
                                                    <Row className='rowheightLCAssets'>
                                                        <Col lg="4" md="4" sm="4" xs="4">
                                                            {
                                                                downloadSpinner || isEnableSave ?
                                                                    <>
                                                                        {
                                                                            closeB2BSelection ?
                                                                                <div className='usedcarselectionstyleLCAssetsAdvanceSearch'>
                                                                                    <span style={{ marginRight: '15px' }}>
                                                                                        <SearchButton
                                                                                            placeholder={'Save'}
                                                                                            handleSearch={handleClickSave}
                                                                                            width='true'
                                                                                        />
                                                                                    </span>
                                                                                    <span>
                                                                                        <SearchButton
                                                                                            placeholder={'Cancel'}
                                                                                            handleSearch={handleCloseB2BButton}
                                                                                            rsa='true'
                                                                                            width='true'
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                                :
                                                                                <div className='spinnerStyleAdvancedLCAssets'>
                                                                                    <FadeLoader color="#1EF1C6" radius={1} width={3} height={8} margin={-5} />
                                                                                </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className='optionsSelectLCAssetsAdvanced'>
                                                                            {
                                                                                matchesPermission ?
                                                                                    <FormSelect
                                                                                        menuObjectArray={[
                                                                                            { 'key': '1', 'value': 'Close Advanced Search' },
                                                                                            { 'key': '2', 'value': 'Download' },
                                                                                            { 'key': '3', 'value': 'Used Car B2B' },
                                                                                        ]}
                                                                                        onClick={haandleDropdown}
                                                                                    />
                                                                                    :
                                                                                    <FormSelect
                                                                                        menuObjectArray={[
                                                                                            { 'key': '1', 'value': 'Close Advanced Search' },
                                                                                            { 'key': '2', 'value': 'Download' }
                                                                                        ]}
                                                                                        onClick={haandleDropdown}
                                                                                    />
                                                                            }
                                                                        </div>
                                                                    </>
                                                            }
                                                        </Col >
                                                    </Row>
                                                }
                                            </Col>
                                            {
                                                <Fade bottom duration={3000}>
                                                    <div className="tableFixHeadLCAssetsAdvance">
                                                        <Row>
                                                            <Col md={12} sm={12} xs={12} lg={12}>
                                                                <ReactTableBaseVehiclePage
                                                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                                                    columns={columns}
                                                                    data={rows}
                                                                    updateEditableData={updateEditableData}
                                                                    updateDraggableData={updateDraggableData}
                                                                    tableConfig={tableConfig}
                                                                    isColumnHidden={isColumnHidden}
                                                                    isSelected={isSelected}
                                                                    selectedRowValues={selectedRowValues}
                                                                    earmarkedRowValues={earmarkedRowValues}
                                                                    earMarkedRows={earMarked}
                                                                    handleDrawar={handleDrawar}
                                                                    closeHandleDrawer={closeHandleDrawer}
                                                                    vehiclePosition={vehiclePosition}
                                                                    selected={selected}
                                                                    isExpanded={isExpanded}
                                                                    selectAllRows={selectAllRows}
                                                                    isPageSelectAll={isPageSelectAll}
                                                                    handleChangePage={() => handleChangePage(counterPagination)}
                                                                    disablePagination={disablePagination}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='paginationLCAssetsAdvancedSearch' style={{ fontFamily: 'LynkcoTypeRegular' }}>Total Records: {formatAndVerifyNumericValue(totalItems)}</div>
                                                </Fade>
                                            }
                                        </Col>

                                        <Col md={3} sm={3} xs={3} lg={2}>
                                            <Fade bottom duration={1500}>
                                                <div className={drawerClasses.root} >
                                                    <div ref={div}>
                                                        <UsedCarStockCard
                                                            setQueryParamsUsedCarStock={setQueryParamsUsedCarStock}
                                                            setDataLoaded={setDataLoaded}
                                                            userCarStockCount={userCarStockCount}
                                                            usedCarToggle={usedCarToggle}
                                                        />
                                                        <UsedCarReadyForSaleCard
                                                            setQueryParamsAvailableForSales={setQueryParamsAvailableForSales}
                                                            setDataLoaded={setDataLoaded}
                                                            availableForSalesCount={availableForSalesCount}
                                                            setUsedCarToggle={setUsedCarToggle}
                                                        />
                                                        <WorkOrdersCard
                                                            setQueryParamsNoOpenWorkOrders={setQueryParamsNoOpenWorkOrders}
                                                            setDataLoaded={setDataLoaded}
                                                            noOpenWorkOrderCount={noOpenWorkOrderCount}
                                                        />
                                                        <RVBelowLCCard
                                                            setQueryParamsRvBelowLandedCost={setQueryParamsRvBelowLandedCost}
                                                            setDataLoaded={setDataLoaded}
                                                            rvBelowLCCount={rvBelowLCCount}
                                                        />

                                                        <FleetTypeCard
                                                            setPresSelectedFleetTypes={setPresSelectedFleetTypes}
                                                            setDataLoaded={setDataLoaded}
                                                            setSelectedFleetType={setSelectedFleetType}
                                                            setQueryParamsFleetTypeIds={setQueryParamsFleetTypeIds}
                                                            setFleetTypeIdscongfig={setFleetTypeIdscongfig}
                                                        />

                                                        <MarketCard
                                                            setDataLoaded={setDataLoaded}
                                                            setQueryParamsMarketIds={updateQueryParamsMarketIds}
                                                            setMarketconfig={setMarketconfig}
                                                            multiMarketSelect={multiMarketSelect}
                                                            isStandardMarket="true"
                                                        />

                                                        <AgeRangeCCard
                                                            setQueryParamsAge={setQueryParamsAge}
                                                            setDataLoaded={setDataLoaded}
                                                        />

                                                        <RVRangeCard
                                                            setQueryParamsRvRange={setQueryParamsRvRange}
                                                            setDataLoaded={setDataLoaded}
                                                        />

                                                        <VehicleColourCard
                                                            queryParamsColor={queryParamsColor}
                                                            setQueryParamsColor={setQueryParamsColor}
                                                            setDataLoaded={setDataLoaded}
                                                        />

                                                        <EngineCard
                                                            queryParamsEngine={queryParamsEngine}
                                                            setQueryParamsEngine={setQueryParamsEngine}
                                                            setDataLoaded={setDataLoaded}
                                                        />

                                                        <TowbarCard
                                                            setQueryParamsFilteredTowbar={setQueryParamsFilteredTowbar}
                                                            setDataLoaded={setDataLoaded}
                                                            towBarCount={towBarCount}
                                                        />

                                                        <ModelYearCard
                                                            setDataLoaded={setDataLoaded}
                                                            setModelYearToggle={setModelYearToggle}
                                                            setQueryParamsModelYear={setQueryParamsModelYear}
                                                        />

                                                        <OrderTypeCard
                                                            setQueryParamsOrderType={setQueryParamsOrderType}
                                                            setDataLoaded={setDataLoaded}
                                                            queryParamsOrderType={queryParamsOrderType}
                                                        />
                                                    </div >
                                                </div>
                                            </Fade>

                                            <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}  >
                                                <Tooltip title={iconDirection ? "Scroll Up" : "Scroll Down"}
                                                    className={drawerClasses.rotateIcon}
                                                    onClick={() => {
                                                        iconDirection ?
                                                            div.current?.scrollIntoView({ behavior: "smooth", alignToTop: "true" }) : div.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: 'nearest' })
                                                        setIconDirection(!iconDirection)
                                                    }}
                                                >
                                                    {
                                                        iconDirection ?
                                                            <span>
                                                                <img
                                                                    className="dot"
                                                                    src={scroll_up}
                                                                    alt="Three Dot Image"
                                                                />
                                                            </span>
                                                            :
                                                            <span>
                                                                <img
                                                                    className="dot"
                                                                    src={scroll_down}
                                                                    alt="Three Dot Image"
                                                                />
                                                            </span>
                                                    }
                                                </Tooltip>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <div id='tablecard' >
                                    <Row className='headerStyleMyOpenCasesLCAssets'>
                                        <Col lg="8" md="8" sm="8" xs="8" ></Col>
                                        <Col lg="4" md="4" sm="4" xs="4" >
                                            {
                                                <Row className='rowheightLCAssets'>
                                                    <Col lg="4" md="4" sm="4" xs="4" >
                                                        {
                                                            <Row>
                                                                <Col lg="4" md="4" sm="4" xs="4">
                                                                    {
                                                                        downloadSpinner || isEnableSave ?
                                                                            <>
                                                                                {
                                                                                    closeB2BSelection ?
                                                                                        <div className='usedcarselectionstyleLCAssets'>
                                                                                            <span style={{ marginRight: '15px' }}>
                                                                                                <SearchButton
                                                                                                    placeholder={'Save'}
                                                                                                    handleSearch={handleClickSave}
                                                                                                    // height='true'
                                                                                                    width='true'
                                                                                                />
                                                                                            </span>
                                                                                            <span style={{}}>
                                                                                                <SearchButton
                                                                                                    placeholder={'Cancel'}
                                                                                                    handleSearch={handleCloseB2BButton}
                                                                                                    rsa='true'
                                                                                                    width='true'
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                        :
                                                                                        <div className='spinnerStyleLCAssets'>
                                                                                            <FadeLoader color="#1EF1C6" radius={1} width={3} height={8} margin={-5} />
                                                                                        </div>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    showSearchBar ?
                                                                                        <div className='searchStyleULCAseets'>
                                                                                            <SearchField
                                                                                                handleSearchbar={handleSearchbar}
                                                                                                placeholder='VIN or License Plate'
                                                                                                handleSearch={handleExtendedSearch}
                                                                                                handleSearchInput={handleSearchInput}
                                                                                                onKeyPress={textFieldKeypressSearch}
                                                                                                value={queryParamsVin}
                                                                                                priceBucketActiveTab='true'
                                                                                                autoFocus='true'
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        <Tooltip
                                                                                            title="open search"
                                                                                            onOpen={handleOpenMagnifyIcon}
                                                                                            onClose={handleCloseMagnifyIcon}
                                                                                            style={{ zIndex: 200 }}
                                                                                        >
                                                                                            <IconButton
                                                                                                onClick={handleCloseSearchbar}
                                                                                            >
                                                                                                <img
                                                                                                    src={iconMagnify}
                                                                                                    className="magnifyIconLCAsset"
                                                                                                />
                                                                                            </IconButton>
                                                                                        </Tooltip>

                                                                                }
                                                                                <div className={showSearchBar ? 'optionsSelectLCAssetsSearch' : 'optionsSelectLCAssets'}>
                                                                                    {
                                                                                        matchesPermission ?
                                                                                            <FormSelect
                                                                                                menuObjectArray={[
                                                                                                    { 'key': '1', 'value': 'Open Advanced Search' },
                                                                                                    { 'key': '2', 'value': 'Download' },
                                                                                                    { 'key': '3', 'value': 'Used Car B2B' },
                                                                                                ]}
                                                                                                onClick={haandleDropdown}
                                                                                            />
                                                                                            :
                                                                                            <FormSelect
                                                                                                menuObjectArray={[
                                                                                                    { 'key': '1', 'value': 'Open Advanced Search' },
                                                                                                    { 'key': '2', 'value': 'Download' }
                                                                                                ]}
                                                                                                onClick={haandleDropdown}
                                                                                            />
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </Col >
                                                            </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                        {
                                            <Fade bottom duration={3000}>
                                                <div className="tableFixHeadLCAssets">
                                                    <Row>
                                                        <Col md={12} sm={12} xs={12} lg={12}>
                                                            <ReactTableBaseVehiclePage
                                                                key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                                                columns={columns}
                                                                data={rows}
                                                                updateEditableData={updateEditableData}
                                                                updateDraggableData={updateDraggableData}
                                                                tableConfig={tableConfig}
                                                                isColumnHidden={isColumnHidden}
                                                                isSelected={isSelected}
                                                                selectedRowValues={selectedRowValues}
                                                                earmarkedRowValues={earmarkedRowValues}
                                                                earMarkedRows={earMarked}
                                                                handleDrawar={handleDrawar}
                                                                closeHandleDrawer={closeHandleDrawer}
                                                                vehiclePosition={vehiclePosition}
                                                                selected={selected}
                                                                isExpanded={isExpanded}
                                                                selectAllRows={selectAllRows}
                                                                isPageSelectAll={isPageSelectAll}
                                                                handleChangePage={() => handleChangePage(counterPagination)}
                                                                disablePagination={disablePagination}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='paginationLCAssets' style={{ fontFamily: 'LynkcoTypeRegular' }}>Total Records: {formatAndVerifyNumericValue(totalItems)}</div>
                                            </Fade>
                                        }
                                    </Row>
                                </div>
                        }
                    </>}
            </div>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state),
    graphData: selectUserGraphData(state),
    userParentOrg: selectUserParentOrganization(state)
});

export default connect(mapStateToProps, null)(M2MTable);