import React, { useState, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { selectSideBarShow } from 'src/redux/sidebar/sidebar.selectors';
import { selectAdUserDetails, selectUserOrganization } from 'src/redux/user/user.selectors';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SearchOrganizationsByApplicationId } from 'src/services/organization-http.service';
import { environment } from 'src/environments/environment';
import { FormmatDate } from 'src/utils/DateFormatter';
import DefaultPage from 'src/pages/backgrounds/vehicleDetails-default-page';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import routes from 'src/routes';



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Content = ({ userOrg }) => {
  const [displayMainPage, setDisplayMainPage] = useState(false)

  useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = () => {
    let organizationArray = [];

    SearchOrganizationsByApplicationId(environment.applicationId).then(data => {
      Array.isArray(data) ? data.map(org => {
        organizationArray.push(
          {
            name: org.name,
            id: org.id,
            viewFleetIds: org.viewFleetIds === null ? 0 : org.viewFleetIds?.length,
            createdDateUtc: FormmatDate(org.createdDateUtc)
          }
        );
      })
        : console.log("Error Occured");
      let filter = organizationArray.some(x => x.id === userOrg.id);
      setDisplayMainPage(filter);
    })
  };

  return (
    <>
      {
        displayMainPage ?
          <div className="black-background">
            <Suspense fallback={loading}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        <route.component {...props} />
                      )} />
                  )
                })}
                <Redirect from="/" to="/admin/users" />
              </Switch>
            </Suspense>
          </div>
          :
          <DefaultPage backgroundtype="true" dashboardstyle="true" />
      }
    </>
  );
}

const mapStateToProps = state => ({
  userData: selectAdUserDetails(state),
  open: selectSideBarShow(state),
  userOrg: selectUserOrganization(state),
});

export default connect(mapStateToProps, null)(React.memo(Content));
